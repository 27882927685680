import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const AltmanZScore = ({ companyData }) => {
  const ttmKey = [
    "workingCapital",
    "totalAssets",
    "retainedEarnings",
    "ebit",
    "currentMarketPrice",
    "marketValueOfEquity",
    "revenue",
  ];

  const calculationKeys = ["A", "B", "C", "D", "E"];

  const resultKey = ["AltmanZScore"];
  return (
    <div className="flex gap-10">
      <div>
        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={2}>
                  TTM Values
                </TableCell>
                {/* <TableCell align="right">Calories</TableCell>
              <TableCell align="right">Fat&nbsp;(g)</TableCell>
              <TableCell align="right">Carbs&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map((row, i) => {
                return (
                  ttmKey.includes(row.key) && (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={row.title === "Ebit" ? "uppercase" : ""}
                        component="th"
                      >
                        {row.title}
                      </TableCell>
                      <TableCell>{row.values}</TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <div>
          <TableContainer>
            <Table className="border mt-5">
              <TableHead>
                <TableRow className="!bg-[#161a20]">
                  <TableCell className="!text-center !bg-[#2c3e50]" colSpan={3}>
                    Calculations
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyData.map((row, i) => {
                  return (
                    calculationKeys.includes(row.key) && (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th">
                          {row.key === "A" && "Working Capital/Total Assets "}
                          {row.key === "B" &&
                            "Retained Earnings/Total Assets  "}
                          {row.key === "C" && "EBIT/Total Assets  "}
                          {row.key === "D" &&
                            "Market Value of Equity/Total Assets "}
                          {row.key === "E" && "Total Sales/Total Assets  "}
                        </TableCell>

                        <TableCell component="th">{row.title}</TableCell>
                        <TableCell>{row.values}</TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <TableContainer>
            <Table className="border mt-5">
              <TableHead>
                <TableRow className="!bg-[#161a20]">
                  <TableCell className="!text-center !bg-[#2c3e50]" colSpan={2}>
                    Results
                  </TableCell>
                  {/* <TableCell align="right">Calories</TableCell>
              <TableCell align="right">Fat&nbsp;(g)</TableCell>
              <TableCell align="right">Carbs&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {companyData.map((row, i) => {
                  return (
                    resultKey.includes(row.key) && (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th">Altman-Z Score</TableCell>
                        <TableCell>{row.values}</TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default AltmanZScore;
