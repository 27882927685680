import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useEffect, useState } from "react";
import { StockOverview } from "./StockOverview";
import { StockDaily } from "./StockDaily";
import { StockMonthly } from "./StockMonthly";
import { StockYearly } from "./StockYearly";
import { Autocomplete, Box, TextField } from "@mui/material";
import axios from "axios";

export const StockCard = () => {
  const [value, setValue] = useState("1");
  const [companyList, setCompanyList] = useState([]);
  const [companyName, setCompanyName] = useState({
    symbol: "AAPL",
    logo_url: "https://financialmodelingprep.com/image-stock/AAPL.png",
    name: "Apple Inc.",
  });
  const [companySearch, setCompanySearch] = useState("");

  const fetchData = async () => {
    await axios
      .get(
        `https://company.stockiworld.com/api/company-details/?page=1&page_size=50`
      )
      .then((res) => {
        // companyList.current = [...res.data]
        setCompanyList([...res.data]);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      axios
        .get(
          `https://company.stockiworld.com/api/company-details/?page=1&symbol=${companySearch}`
        )
        .then((response) => {
          // companyList.current = response.data;
          setCompanyList([...response.data]);
        });
    }, 2000);

    return () => clearTimeout(getData);
  }, [companySearch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="h-full">
        <div className="flex gap-5 mb-5">
          <Autocomplete
            id="country-select-demo"
            sx={{ width: 300 }}
            options={[...companyList]}
            filterOptions={(options) => options}
            // open
            // autoHighlight
            // disableClearable

            getOptionLabel={(option) => option.name}
            onChange={(event, newValue, reason) => {
              reason === "clear"
                ? fetchData()
                : setCompanyName(
                    newValue
                      ? newValue
                      : {
                          symbol: "AAPL",
                          name: "Apple Inc.",
                        }
                  );
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  // loading="lazy"
                  width="20"
                  // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={option.logo_url}
                  alt=""
                />
                {option.symbol} - {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a company"
                className="text-white"
                onChange={(e) => {
                  setCompanySearch(e.target.value);
                }}
                // onBlur={(e) => fetchData()}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <span className="border border-red-600 p-4 rounded-lg flex items-center gap-3">
            <img
              src={companyName.logo_url}
              alt={companyName.name}
              className="h-5 w-5"
            />{" "}
            {companyName.symbol} - {companyName.name}
          </span>
        </div>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              disableRipple={true}
              style={{
                fontSize: "16px",
                padding: "4px 10px",
                textTransform: "capitalize",
              }}
              label="Overview"
              value="1"
            />
            <Tab
              disableRipple={true}
              style={{
                fontSize: "16px",
                padding: "4px 10px",
                textTransform: "capitalize",
              }}
              label="Daily Return"
              value="2"
            />
            <Tab
              disableRipple={true}
              style={{
                fontSize: "16px",
                padding: "4px 10px",
                textTransform: "capitalize",
              }}
              label="Monthly Return"
              value="3"
            />
            <Tab
              disableRipple={true}
              style={{
                fontSize: "16px",
                padding: "4px 10px",
                textTransform: "capitalize",
              }}
              label="Yearly Return"
              value="4"
            />
          </TabList>

          <TabPanel className="h-[calc(100%-48px)] overflow-y-auto" value="1">
            <StockOverview />
          </TabPanel>
          <TabPanel className="h-[calc(100%-48px)] overflow-y-auto" value="2">
            <StockDaily companyName={companyName} companyList={companyList} />
          </TabPanel>
          <TabPanel className="h-[calc(100%-48px)] overflow-y-auto" value="3">
            <StockMonthly companyName={companyName} companyList={companyList} />
          </TabPanel>
          <TabPanel className="h-[calc(100%-48px)] overflow-y-auto" value="4">
            <StockYearly companyName={companyName} companyList={companyList} />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};
