import PharmbizznewComponent from '../../Components/PharmbizznewsComponents';

export default function Pharmbizznews() {

  return (
    <>
      <PharmbizznewComponent />
    </>
  )
}
