import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const Dupont = ({ companyData }) => {
  console.log("Dupont", companyData);
  const resultKey = [
    "calendarYear",
    "revenue",
    "ebit",
    "ebt",
    "netIncome",
    "totalAssete",
    "shareholdersEquity",
    "taxBurden",
    "interestBurden",
    "ebitMargin",
    "assetTurnover",
    "financialLeverage",
    "roe",
  ];

  const boldKey = [
    "taxBurden",
    "interestBurden",
    "ebitMargin",
    "assetTurnover",
    "financialLeverage",
    "roe",
  ];

  return (
    <div className="flex gap-10">
      <TableContainer>
        <Table className="border mt-5">
          <TableHead>
            <TableRow className="!bg-[#161a20]">
              <TableCell className="!text-center" colSpan={11}>
                Dupont Analysis
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyData.map((row, i) => {
              return (
                resultKey.includes(row.key) && (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={
                      row.key === "calendarYear" ? "!bg-[#2c3e50]" : ""
                    }
                  >
                    <TableCell
                      className={`${
                        row.title === "Roe" ||
                        row.key === "ebit" ||
                        row.key === "ebt"
                          ? "uppercase"
                          : ""
                      } ${
                        boldKey.includes(row.key)
                          ? "font-extrabold"
                          : "font-extralight"
                      } ${
                        boldKey.includes(row.key) ? "font-bold !text-xl" : ""
                      }`}
                      component="th"
                    >
                      {row.title}
                    </TableCell>
                    {Array.isArray(row.values)
                      ? row.values?.map((rowItem, rowInd) => {
                          return (
                            <TableCell
                              key={rowInd}
                              className={`!text-center ${
                                boldKey.includes(row.key)
                                  ? "font-bold !text-xl"
                                  : ""
                              }`}
                            >
                              <span>
                                {rowItem ? rowItem : "-"}
                                {row.title === "Roe" && "%"}
                              </span>
                            </TableCell>
                          );
                        })
                      : row.values}
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Dupont;
