import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  CardActions,
  Button,
  Divider,
} from "@mui/material";
// ff170cfbc214454f0a10844eb6e9606e

import "./Styles/index.css";
import HeatMap from "./components/HeatMap";
import SearchCard from "./components/SearchCard";
import DailyComu from "./components/DailyComu";
import MiniCards from "./components/MiniCards";
import TableComp from "./components/TableComp";
import TopTable from "./components/TopTable";
import BuzzingChart from "./components/BuzzingChart";
import SentimentCard from "./components/SentimentCard";
import HorizontalChart from "./components/HorizontalChart";
import MiniCardsBearish from "./components/MiniCardsBearish";
import { useSelector, useDispatch } from "react-redux";
import { useCoinActions } from "./_actions/coin.actions";
import { getSentiment } from "./dailySentimentSlice";

const DailySentiment = () => {
  const sentimentSliceData = useSelector((state) => state.sentiment.value);
  const sentimentData = sentimentSliceData.topSentiment;
  const searchData = sentimentSliceData.searchStockData;
  const dailyComuData = sentimentSliceData.searchGraphData;
  const dailyHotListData = sentimentSliceData.hotListData;
  const fixedTableData = sentimentSliceData.fixedListData;
  const [fetchedSentimentData1, setFetchedSentimentData1] = useState([]);
  const [fetchedTopMentionStock, setFetchedTopMentionStock] = useState([]);

  const fetchSentimentData = async () => {
    try {
      console.log("Fetching daily heatmap data...");
      const DailyStockResponse = await fetch(
        "https://dev-sentiment.stockiworld.com/daily-stock-graph?symbol=SPY" //http://localhost:5000
      );
      console.log(
        "Response status for daily heatmap data:",
        DailyStockResponse
      );
      const data = await DailyStockResponse.json();
      setFetchedSentimentData1(data.sentiment_graph);
    } catch (error) {
      console.error("Error fetching daily heatmap data:", error);
    }
  };

  const fetchTopMentionStock = async () => {
    try {
      console.log("Fetching topstock mention data...");
      const TopMentionStockResponse = await fetch(
        "https://dev-sentiment.stockiworld.com/daily-hot-list" //http://localhost:5000
      );
      console.log(
        "Response status for topstock mention data:",
        TopMentionStockResponse.status
      );
      const data = await TopMentionStockResponse.json();
      setFetchedTopMentionStock(data.data);
    } catch (error) {
      console.error("Error fetching topstock data:", error);
    }
  };

  useEffect(() => {
    fetchTopMentionStock();
    fetchSentimentData();
  }, []);

  const coinActions = useCoinActions();
  const dispatch = useDispatch();
  const [intervalCount, setIntervalCount] = useState(0);

  useEffect(() => {
    coinActions.predictionListing();
    coinActions.searchSymbol();
    coinActions.sentimentGraph();
    coinActions.dailyHotList();
    coinActions.stockPrice();
    coinActions.sp500Sentiment();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCount(intervalCount + 1);
      coinActions.predictionListing();
      coinActions.searchSymbol();
      coinActions.sentimentGraph();
      coinActions.dailyHotList();
      coinActions.stockPrice();
    }, 200000);
    return () => clearInterval(interval);
  }, [intervalCount]);

  return (
    <>
      {/* first row */}

      <Box sx={{ mb: 2 ,mt:2 }}>
        <Grid container spacing={0.5} className="p-2 bg-black border-100">
          <Grid item md={2.8} xs={12}>
            <Paper
              className="paper-back"
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%", // Ensure equal height
             
              }}
            >
              <Typography>Daily Sentiment Heat Map</Typography>
              <HeatMap sentimentData={fetchedSentimentData1} />
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper
              className="paper-back m-2"
              sx={{
                px: 1,
                py: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%", // Ensure equal height
             
              }}
            >
              <SearchCard searchData={fetchedTopMentionStock} />
            </Paper>
          </Grid>

          <Grid item md={3.1} xs={12}>
            <Paper
              className="paper-back"
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%", // Ensure equal height
             
              }}
            >
              <Typography>Daily Cumulative 5 Min</Typography>
              <DailyComu sentimentData={fetchedTopMentionStock} />
            </Paper>
          </Grid>

          <Grid item md={3.1} xs={12}>
            <Paper
              className="paper-back"
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%", // Ensure equal height
             
              }}
            >
              <Typography>Top Buzzing Stocks</Typography>
              <BuzzingChart sentimentData={fetchedTopMentionStock} />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* second row */}
      <Box sx={{ flexGrow: 1, mb: 1 }}>
        <Grid container spacing={1}>
          {/* first item - column */}
          <Grid
            container
            item
            md={6}
            xs={12}
            spacing={1}
            sx={{ display: "flex", alignItems: "center", height: "26vh" }}
          >
            <Grid item md={6} xs={12} sx={{ height: "40%" }}>
              <MiniCards />
            </Grid>
            <Grid item md={6} xs={12} sx={{ height: "40%" }}>
              <MiniCardsBearish sentimentData={fetchedTopMentionStock} />
            </Grid>
          </Grid>
          
          {/* second item - colu6mn */}
          {/* //no need to add for now */}
          {/* <Grid item md={3} xs={12}>
            <Paper className="paper-back" sx={{ p: 0, height: "25vh" }}>
              <HorizontalChart sentimentData={fetchedTopMentionStock} />
            </Paper>
          </Grid> */}
          {/* third item - column */}

          <Grid container item md={6} xs={12}>
            <Box
              className="sentiment-circle"
              sx={{
                height: "23.5vh",
                width: "100%",
                border: "2px solid #101F2C",
                display: "flex",
                p: 0.5,
                overflowX: "scroll",
                borderRadius: "0.6rem",
              }}
            >
              <Grid
                item
                md={4}
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <SentimentCard sentimentData={fetchedSentimentData1} />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* third row */}
      <Box sx={{ flexGrow: 1, mb: 1 }}>
  <Grid container item spacing={0.5}>
    {/* first item */}
    <Grid item md={5.8} xs={12}>
      <Paper className="paper-back" sx={{ p: 1, height: "28vh" }}>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold", mb: 1 }}>
          Top Mentions Stock - Wallstreet Bet
        </Typography>

        <TopTable dailyHotListData={fetchedTopMentionStock} />
      </Paper>
    </Grid>
    {/* second item */}
    <Grid item md={5.8} xs={12}>
      <Paper className="paper-back" sx={{ p: 1, height: "28vh" }}>
      <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold", mb: 1 }}>
          Top Mentions Stock - Wallstreet Bet
        </Typography>

        <TableComp />
      </Paper>
    </Grid>
  </Grid>
</Box>

    </>
  );
};

export default DailySentiment;
