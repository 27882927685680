import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { reorderKeys } from "../../utils/ReorderKeys";

const PScore = ({ companyData }) => {
  console.log("pscore", companyData);
  const desiredOrder = [
    "calendarYear",
    "netIncome",
    "roa",
    "ocf",
    "CFO>NI",
    "leverage",
    "liquidity",
    "shareIssue",
    "grossMargin",
    "assetTurnover",
    "piotroskiScore",
  ];

  const tempCompanyData = reorderKeys(companyData, desiredOrder);
  return (
    <div className="flex gap-5">
      <TableContainer>
        <Table aria-label="vertical header table" className="border mt-5">
          <TableBody>
            <TableRow>
              <TableCell colSpan={10} className="!text-center !bg-[#2c3e50]">
                P-Score
              </TableCell>
            </TableRow>
            {tempCompanyData.map((row, i) => (
              <TableRow
                key={i}
                className={`${
                  row.title === "Piotroski Score" || row.title === "Mscore"
                    ? "bg-[#011219]"
                    : ""
                }`}
              >
                <TableCell
                  component="th"
                  className={`${
                    row.key === "roa" || row.key === "ocf" ? "uppercase" : ""
                  } ${
                    row.key === "roa" ||
                    row.key === "ocf" ||
                    row.key === "CFO>NI"
                      ? "cursor-pointer"
                      : ""
                  } ${row.key === "calendarYear" ? "!bg-[#2c3e50]" : ""}`}
                  title={
                    row.key === "roa"
                      ? "Return On Asset"
                      : row.key === "ocf"
                      ? "Operating Cash Flow"
                      : row.key === "CFO>NI"
                      ? "Cash Flow from Operations > Net Income"
                      : ""
                  }
                >
                  {row.key === "calendarYear"
                    ? "Criteria"
                    : row.title === "Mscore"
                    ? "M-score"
                    : row.title}
                </TableCell>

                {Array.isArray(row.values)
                  ? row.values?.map((rowItem, rowInd) => {
                      console.log("rowkey", row.key);
                      return (
                        <TableCell
                          key={rowInd}
                          className={`!text-center ${
                            row.title === "Pscore" || row.key === "calendarYear"
                              ? "!bg-[#2c3e50]"
                              : "uppercase"
                          }`}
                        >
                          <span className={`text-white `}>
                            {rowItem ? rowItem : "-"}
                          </span>
                        </TableCell>
                      );
                    })
                  : row.values}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <TableContainer>
          <Table aria-label="vertical header table" className="border mt-5">
            <TableBody>
              <TableRow>
                <TableCell>P-SCORE</TableCell>
                <TableCell>FUNDAMENTALS</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>8 TO 9</TableCell>
                <TableCell className="!bg-green-500 !w-2">STRONG</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>1-2</TableCell>
                <TableCell className="!bg-red-500 !w-2">WEAK</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table aria-label="vertical header table" className="border mt-5">
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={10}
                  className="!text-center !text-xl !font-bold"
                >
                  EXPLANATION
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Net Income</TableCell>
                <TableCell>
                  Company should have positive net income in the current year
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ROA</TableCell>
                <TableCell>
                  Company needs to have postitive return on assets
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>OCF</TableCell>
                <TableCell>
                  Company needs to have postitive operating cash flow
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CFO>NI</TableCell>
                <TableCell>
                  If company Cash flow from operations is greater than net
                  income, it shows company has quality earnings
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Leverage</TableCell>
                <TableCell>
                  If leverage decreases this year, it increases the value of the
                  company.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Liquidity</TableCell>
                <TableCell>
                  If liquidity increases compared to previous period, it shows
                  company is suistainable in short term period.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Share issue</TableCell>
                <TableCell>
                  If new shares are issued it suggests company has diluted it's
                  shares and hence the ownership decreases for the same amount
                  of shares.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gross margin</TableCell>
                <TableCell>
                  A higher gross margin wrt to previous years shows
                  profitability has increased.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Asset turnover</TableCell>
                <TableCell>
                  If company has increases asset turnover compared to previous
                  period, it suggests company's productivity has increased
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default PScore;
