import { useEffect } from 'react'
import { getFinanceData, resetData } from '../../redux/finance/finance-slice'
import TableData from "./TableData/index"
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { Container } from '@mui/material'
import PieChart from './Chart/PieChart'
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";


const InsiderTradingComponent = () => {

  const data = useAppSelector(store => store.finance.data)
  const dispatch = useAppDispatch()


  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const symbol = queryParams.get("symbol") || "";
  console.log("symbol::", symbol);

  useEffect(() => {
    dispatch(resetData())
    dispatch(getFinanceData(symbol));
  }, [])

  return (
  
      <Container maxWidth="xl" sx={{
        paddingY: "20px"
      }}>
        <TableData dataList={data} />
        <PieChart data={data} />
      </Container>
  )
}

export default InsiderTradingComponent
