/* eslint-disable react/prop-types */
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { Button } from "@mui/material";
import { camelCaseToWords } from "../../utils/CamelCaseToWords";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "./sidebar/sidebar";
import { formatValue } from "../../utils/FormatValue";
import { formatDate } from "../../utils/FormatDate";
import AltmanZScore from "./AltmanZScore";
import SharpeTab from "./SharpeTab";
import InputCAPM from "./InputCAPM";
import DcfTab from "./DCFTab";
import DDMTab from "./DDMTab";
import Graham from "./Graham";
import Dupont from "./Dupont";
import RelativeValuation from "./RelativeValuation";
import PScore from "./PScore";
import MScore from "./MScore";
import ReactApexChart from "react-apexcharts";
import { CashBurnChartData } from "../StockFundamentals/PopularStocks/utils/data";
import RatioComponent from "./Ratio";

const FinancialOverflow = () => {
  const [comapnyName, setComapnyName] = useState({
    symbol: "AAPL",
    name: "Apple Inc.",
    logo_url: "https://financialmodelingprep.com/image-stock/AAPL.png",
  });

  // const companyList = useRef([])
  const [companyData, setCompanyData] = useState([]);
  const [newCompanyData, setNewCompanyData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [defaultPeriod, setDefaultPeriod] = useState("annual");
  const [companyList, setComapnyList] = useState([]);
  const [companySearch, setComapnySearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [isChangingTab, setIsChangingTab] = useState(false);
  const [type, setType] = useState("financial-statements");

  const fetchData = async () => {
    await axios
      .get(
        `https://company.stockiworld.com/api/company-details/?page=1&page_size=50`
      )
      .then((res) => {
        // companyList.current = [...res.data]
        setComapnyList([...res.data]);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setDataLoading(true);
    axios
      .get(
        type === "pscore" || type === "mscore"
          ? `https://company.stockiworld.com/api/pscore-mscore/${defaultPeriod}/${comapnyName.symbol}/`
          : type === "sharpeTreynorJensens"
          ? `https://company.stockiworld.com/api/altmanzscore/${defaultPeriod}/${comapnyName.symbol}/`
          : type === "dupont" || type === "graham"
          ? `https://company.stockiworld.com/api/dupont-graham/${comapnyName.symbol}/`
          : type === "input-capm" ||
            type === "dcf" ||
            type === "ddm" ||
            type === "relativevaluation"
          ? `https://company.stockiworld.com/api/${type}/${comapnyName.symbol}/`
          : `https://company.stockiworld.com/api/${type}/${defaultPeriod}/${comapnyName.symbol}/`
      )
      .then((res) => {
        const formattedData = [];
        const newFormattedData = [];
        let convertedData;

        if (type === "altmanzscore") {
          Object.keys(res.data).map((key, index) => {
            let newObj = {
              key,
              title: camelCaseToWords(key),
              // values: obj[key]
              values: res.data[key],
            };
            formattedData.push(newObj);
          });
          setIsChangingTab(false);

          setCompanyData(formattedData);
          setDataLoading(false);
          return;
        }

        if (type === "sharpeTreynorJensens") {
          Object.keys(res.data.SharpeTreynorJensens).map((key, index) => {
            let newObj = {
              key,
              title: camelCaseToWords(key),
              // values: obj[key]
              values: res.data.SharpeTreynorJensens[key],
            };
            formattedData.push(newObj);
          });
          setIsChangingTab(false);

          setCompanyData(formattedData);
          setDataLoading(false);
          return;
        }

        if (type === "dcf" || type === "ddm") {
          Object.keys(res.data).map((key, index) => {
            let newObj = {
              key,
              title: camelCaseToWords(key),
              // values: obj[key]
              values: [res.data[key]],
            };
            formattedData.push(newObj);
            return false;
          });
          setIsChangingTab(false);

          setCompanyData(formattedData);
          setDataLoading(false);
          return;
        }

        if (type === "dupont") {
          const transformedData = {};

          res.data.dap.forEach((entry) => {
            Object.entries(entry).forEach(([key, value]) => {
              if (key !== "calendarYear") {
                if (!transformedData[key]) {
                  transformedData[key] = [];
                }
                transformedData[key].push(value);
              } else {
                if (!transformedData[key]) {
                  transformedData[key] = [];
                }
                transformedData[key].push(Number(value));
              }
            });
          });

          Object.keys(transformedData).map((key, index) => {
            let newObj = {
              key,
              title: camelCaseToWords(key),
              // values: obj[key]
              values:
                key === "calendarYear"
                  ? transformedData[key]
                  : formatValue(transformedData[key]),
            };
            formattedData.push(newObj);
          });
          setIsChangingTab(false);

          setCompanyData(formattedData);
          setDataLoading(false);
          return;
        }

        if (type === "graham") {
          Object.keys(res.data.graham).map((key, index) => {
            let newObj = {
              key,
              title: camelCaseToWords(key),
              // values: obj[key]
              values: res.data.graham[key],
            };
            formattedData.push(newObj);
          });
          setIsChangingTab(false);

          setCompanyData(formattedData);
          setDataLoading(false);
          return;
        }

        if (type === "relativevaluation") {
          const transformedData = {};

          res.data.forEach((entry) => {
            Object.entries(entry).forEach(([key, value]) => {
              if (key !== "calendarYear") {
                if (!transformedData[key]) {
                  transformedData[key] = [];
                }
                transformedData[key].push(value);
              } else {
                if (!transformedData[key]) {
                  transformedData[key] = [];
                }
                transformedData[key].push(Number(value));
              }
            });
          });

          Object.keys(transformedData).map((key, index) => {
            let newObj = {
              key,
              title: camelCaseToWords(key),
              // values: obj[key]
              values: formatValue(transformedData[key]),
            };
            formattedData.push(newObj);
          });
          setIsChangingTab(false);

          setCompanyData(formattedData);
          setDataLoading(false);
          return;
        }

        if (type === "input-capm") {
          Object.keys(res.data).map((key, index) => {
            if (key === "stock_data") {
              Object.keys(res.data.stock_data).map((key1, i) => {
                let newObj = {
                  key1,
                  title: camelCaseToWords(key1),
                  // values: obj[key]
                  values: res.data.stock_data[key1],
                };
                formattedData.push(newObj);
              });
            } else {
              let newObj = {
                key,
                title: camelCaseToWords(key),
                // values: obj[key]
                values: res.data[key],
              };
              newFormattedData.push(newObj);
            }
          });
          setIsChangingTab(false);

          setCompanyData(formattedData);
          setNewCompanyData(newFormattedData);
          setDataLoading(false);
          return;
        }

        let obj =
          type === "pscore" || type === "mscore"
            ? type === "pscore"
              ? Object.values(res.data)[0]
              : Object.values(res.data)[1]
            : Object.keys(res.data)[0] !== "period"
            ? Object.values(res.data)[0]
            : Object.values(res.data)[1];

        type === "pscore" || type === "mscore"
          ? (convertedData = obj.reduce((result, item) => {
              for (const key in item) {
                result[key] = result[key] || [];
                result[key].push(item[key]);
              }
              return result;
            }, {}))
          : console.log("abcd");

        type === "pscore" || type === "mscore"
          ? Object.keys(convertedData).map((key, index) => {
              let newObj = {
                key,
                title: camelCaseToWords(key),
                // values: obj[key]
                values: convertedData[key],
              };
              formattedData.push(newObj);
            })
          : Object.keys(obj).map((key, index) => {
              let nextKey = Object.keys(obj)[index + 1];

              if (key === "period" && nextKey === "calendarYear") {
                let newObj = {
                  key,
                  title: "",
                  values: formatValue(obj[key]),
                  year: obj[nextKey],
                };
                formattedData.push(newObj);
              }

              if (!key.includes("_ratio")) {
                let newObj = {
                  key,
                  title: camelCaseToWords(key),
                  // values: obj[key]
                  values:
                    key === "date"
                      ? formatDate(obj[key])
                      : formatValue(obj[key]),
                };

                if (nextKey?.includes("_ratio")) {
                  newObj.ratio = obj[nextKey];
                }

                if (key !== "period" && nextKey !== "calendarYear") {
                  if (key !== "calendarYear") {
                    formattedData.push(newObj);
                  }
                }
              }
            });
        setIsChangingTab(false);

        setCompanyData(formattedData);
        setDataLoading(false);
      });
  }, [comapnyName, defaultPeriod, type]);

  useEffect(() => {
    setSearchLoader(true);
    const getData = setTimeout(() => {
      axios
        .get(
          `https://company.stockiworld.com/api/company-details/?page=1&symbol=${companySearch}`
        )
        .then((response) => {
          // companyList.current = response.data;
          setComapnyList([...response.data]);
          setSearchLoader(false);
        });
    }, 2000);

    return () => clearTimeout(getData);
  }, [companySearch]);

  return (
    <div className="bg-primary">
      <div className="main_wrapper">
        <div>
          <Navbar />
        </div>
        <div>
          <Sidebar />
        </div>

        <div className="overflow-auto h-[calc(100vh-64px)] w-[calc(100vw-125px)]">
          <div className="flex w-[calc(100vw-125px)] justify-between items-start pt-5 px-5">
            <div className="grid grid-cols-3 gap-3 pr-5">
              <Button
                variant="outlined"
                className={`custom-button p-5 ${
                  type === "financial-statements" && "active"
                } primary`}
                onClick={() => {
                  setIsChangingTab(true);
                  setIsChangingTab(true);
                  setType("financial-statements");
                }}
              >
                Income Statements
              </Button>
              <Button
                variant="outlined"
                className={`custom-button ${
                  type === "balance-sheet" && "active"
                } primary`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("balance-sheet");
                }}
              >
                Balance Sheets
              </Button>
              <Button
                variant="outlined"
                className={`custom-button ${
                  type === "cash-flow" && "active"
                } primary`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("cash-flow");
                }}
              >
                Cash Flow
              </Button>
            </div>
            <div className="grid grid-cols-8 gap-3 pr-10">
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${type === "ratio" && "active"}`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("ratio");
                }}
              >
                Ratio
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${
                  type === "cash-burn-rate" && "active"
                }`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("cash-burn-rate");
                }}
              >
                Cash burn rate
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${type === "pscore" && "active"}`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("pscore");
                }}
              >
                P Score
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${type === "mscore" && "active"}`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("mscore");
                }}
              >
                M score
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${
                  type === "altmanzscore" && "active"
                }`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("altmanzscore");
                }}
              >
                Altman Z Score
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${
                  type === "sharpeTreynorJensens" && "active"
                }`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("sharpeTreynorJensens");
                }}
              >
                Sharpe, Trey...
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${type === "input-capm" && "active"}`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("input-capm");
                }}
              >
                Input CAPM
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${type === "dcf" && "active"}`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("dcf");
                }}
              >
                DCF
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${type === "ddm" && "active"}`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("ddm");
                }}
              >
                DDM
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${type === "dupont" && "active"}`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("dupont");
                }}
              >
                Dupont
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${type === "graham" && "active"}`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("graham");
                }}
              >
                Graham
              </Button>
              <Button
                variant="outlined"
                disabled={isChangingTab}
                className={`custom-button ${
                  type === "relativevaluation" && "active"
                }`}
                onClick={() => {
                  setIsChangingTab(true);
                  setType("relativevaluation");
                }}
              >
                Relative Valuation
              </Button>
            </div>

            {type === "input-capm" ||
            type === "dcf" ||
            type === "ddm" ||
            type === "dupont" ||
            type === "graham" ||
            type === "relativevaluation" ? (
              <></>
            ) : (
              <div className="flex gap-3">
                <Button
                  variant="outlined"
                  className={`custom-button ${
                    defaultPeriod === "annual" && "active"
                  }`}
                  onClick={() => {
                    setDefaultPeriod("annual");
                  }}
                >
                  Annual
                </Button>
                <Button
                  variant="outlined"
                  className={`custom-button ${
                    defaultPeriod === "quarterly" && "active"
                  }`}
                  onClick={() => {
                    setDefaultPeriod("quarterly");
                  }}
                >
                  Quaterly
                </Button>
              </div>
            )}
          </div>

          <div className="my-5 mx-5">
            <div className="flex items-center gap-5">
              <Autocomplete
                id="country-select-demo"
                sx={{ width: 300 }}
                options={[...companyList]}
                filterOptions={(options) => options}
                loading={searchLoader}
                // open
                // autoHighlight
                // disableClearable

                getOptionLabel={(option) => option.name}
                onChange={(event, newValue, reason) => {
                  reason === "clear"
                    ? fetchData()
                    : setComapnyName(
                        newValue
                          ? newValue
                          : {
                              symbol: "AAPL",
                              name: "Apple Inc.",
                            }
                      );
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      // loading="lazy"
                      width="20"
                      // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={option.logo_url}
                      alt=""
                    />
                    {option.symbol} - {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a company"
                    className="text-white"
                    onChange={(e) => {
                      setComapnySearch(e.target.value);
                    }}
                    // onBlur={(e) => fetchData()}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <span className="border border-red-600 p-4 rounded-lg flex items-center gap-3">
                <img
                  src={comapnyName.logo_url}
                  alt={comapnyName.name}
                  className="h-5 w-5"
                />{" "}
                {comapnyName.symbol} - {comapnyName.name}
              </span>
            </div>

            {dataLoading || isChangingTab ? (
              <div className="flex items-center justify-center h-[calc(100vh-158px)]">
                <div className="loader"></div>
              </div>
            ) : type === "altmanzscore" ? (
              <AltmanZScore companyData={companyData} />
            ) : type === "sharpeTreynorJensens" ? (
              <SharpeTab companyData={companyData} />
            ) : type === "input-capm" ? (
              <InputCAPM
                companyData={companyData}
                comapnyName={comapnyName}
                newCompanyData={newCompanyData}
              />
            ) : type === "dcf" ? (
              <DcfTab companyData={companyData} />
            ) : type === "pscore" ? (
              <PScore companyData={companyData} />
            ) : type === "mscore" ? (
              <MScore companyData={companyData} />
            ) : type === "ddm" ? (
              <DDMTab companyData={companyData} />
            ) : type === "dupont" ? (
              <Dupont companyData={companyData} />
            ) : type === "graham" ? (
              <Graham companyData={companyData} />
            ) : type === "relativevaluation" ? (
              <RelativeValuation companyData={companyData} />
            ) : type === "ratio" ? (
              <RatioComponent companyData={companyData} />
            ) : (
              <>
                <TableContainer>
                  <Table
                    aria-label="vertical header table"
                    className="border mt-5"
                  >
                    <TableBody>
                      {companyData.map((row, i) => (
                        <TableRow
                          key={i}
                          className={`${
                            row.title === "Piotroski Score" ||
                            row.title === "Mscore"
                              ? "bg-[#011219]"
                              : ""
                          }`}
                        >
                          <TableCell component="th">{row.title}</TableCell>

                          {Array.isArray(row.values)
                            ? row.values?.map((rowItem, rowInd) => {
                                return (
                                  <TableCell
                                    key={rowInd}
                                    className="!text-center "
                                  >
                                    <span>{rowItem ? rowItem : "-"}</span>
                                    <div>{row.year && row.year[rowInd]}</div>
                                    {row.ratio && (
                                      <div
                                        className={`${
                                          row.ratio[rowInd] > 0
                                            ? "text-green-500"
                                            : "text-red-500"
                                        }`}
                                      >
                                        {row.ratio[rowInd]
                                          ? row.ratio[rowInd] > 0
                                            ? `+${row.ratio[rowInd]}%`
                                            : `${row.ratio[rowInd]}%`
                                          : "-"}
                                      </div>
                                    )}
                                  </TableCell>
                                );
                              })
                            : row.values}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {type === "cash-burn-rate" ? (
                  <div>
                    <ReactApexChart
                      options={CashBurnChartData(companyData).options}
                      series={CashBurnChartData(companyData).series}
                      type="line"
                      height={350}
                    />
                  </div>
                ) : (
                  <p>P</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialOverflow;
