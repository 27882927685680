import ReactApexChart from "react-apexcharts";
import { YearlyReturn } from "../utils/data";
import { useEffect, useState } from "react";
import axios from "axios";

export const StockYearly = (props) => {
  const { companyName, companyList } = props;
  const [CompData, setCompData] = useState([]);
  const [spyCompData, setSpyCompData] = useState([]);
  const [qqqCompData, setQqqCompData] = useState([]);

  const fetchCompanyData = async () => {
    await axios
      .get(
        `https://company.stockiworld.com/api/percentage-return/y/${companyName?.symbol}/`
      )
      .then((res) => {
        setCompData(res.data[companyName?.symbol]);
      });
  };

  const fetchSPYData = async () => {
    await axios
      .get(`https://company.stockiworld.com/api/percentage-return/y/SPY/`)
      .then((res) => {
        setSpyCompData(res.data.SPY);
      });
  };
  const fetchQQQData = async () => {
    await axios
      .get(`https://company.stockiworld.com/api/percentage-return/y/QQQ/`)
      .then((res) => {
        setQqqCompData(res.data.QQQ);
      });
  };

  useEffect(() => {
    fetchSPYData();
    fetchQQQData();
  }, []);

  useEffect(() => {
    fetchCompanyData();
  }, [companyName?.symbol]);

  const yearlyGraphData = [
    {
      name: companyName.symbol,
      data: CompData.map((item) => Number(item.percentageReturn).toFixed(3)),
    },
    {
      name: "SPY",
      data: spyCompData.map((item) => Number(item.percentageReturn).toFixed(3)),
    },
    {
      name: "QQQ",
      data: qqqCompData.map((item) => Number(item.percentageReturn).toFixed(3)),
    },
  ];

  YearlyReturn.options.xaxis.categories = CompData.map((item) => item.year);

  console.log(YearlyReturn);

  return (
    <div className="h-full">
      <div className="h-full flex flex-col justify-between">
        <div className="border-2 border-[#ffffff3b] rounded-lg h-[38%] mx-auto p-4 overflow-y-auto overflow-x-auto w-full">
          <h2 className="text-base font-semibold">Yearly Return</h2>
          <div className="mt-4 w-full">
            <table className="w-full">
              <thead>
                <tr className="text-base text-yellow-300 mb-2">
                  <th className="pr-4 pb-2">Year</th>
                  {CompData.map((item, index) => (
                    <th className="pr-4">{item.year}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className="text-base text-white border-b-[1px] border-[#ffffff13]">
                  <td className="py-2 text-center">{companyName?.symbol}</td>
                  {CompData.map((item, index) => (
                    <td className="px-2 text-center">
                      {Number(item.percentageReturn).toFixed(2)}%
                    </td>
                  ))}
                </tr>
              </tbody>
              <tbody>
                <tr className="text-base text-white border-b-[1px] border-[#ffffff13]">
                  <td className="py-2 text-center">SPY</td>
                  {spyCompData.map((item, index) => (
                    <td className="px-2 text-center">
                      {Number(item.percentageReturn).toFixed(2)}%
                    </td>
                  ))}
                </tr>
              </tbody>
              <tbody>
                <tr className="text-base text-white border-b-[1px] border-[#ffffff13]">
                  <td className="py-2 text-center">QQQ</td>
                  {qqqCompData.map((item, index) => (
                    <td className="px-2 text-center">
                      {Number(item.percentageReturn).toFixed(2)}%
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="h-[58%] w-full ">
          <div className="h-full w-full flex">
            <div className="w-2/4 border-2 border-[#ffffff3b] rounded-lg overflow-y-auto p-4">
              <p className="text-lg font-semibold mb-3">Yearly Performance</p>
              <table className="w-full">
                <thead>
                  <tr className="text-base text-yellow-300 mb-2">
                    <th className="pb-2">Year</th>
                    <th className="pb-2">Open</th>
                    <th className="pb-2">High</th>
                    <th className="pb-2">Low</th>
                    <th className="pb-2">Close</th>
                    <th className="pb-2">Change(%)</th>
                    <th className="pb-2">Avg. Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {CompData.map((item, index) => (
                    <tr className="text-base text-white border-b-[1px] border-[#ffffff13]">
                      <td className="py-2 text-center">{item?.year}</td>
                      <td className="px-2 text-center">{item?.open}</td>
                      <td className="px-2 text-center">{item?.high}</td>
                      <td className="px-2 text-center">{item?.low}</td>
                      <td className="px-2 text-center">{item?.close}</td>
                      <td className="px-2 text-center">
                        {item?.percentageReturn}
                      </td>
                      <td className="px-2 text-center">
                        {Number(item?.average_price).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="w-2/4">
              <ReactApexChart
                options={YearlyReturn.options}
                series={yearlyGraphData}
                type="bar"
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
