import React from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";

const SearchComp = ({ setInputVal, inputVal }) => {
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    // position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = {
    color: "inherit",
    padding: "5px",
    paddingLeft: `3%`,
    width: "15%",
    backgroundColor: "#ffffff26",
  };
  return (
    <div className="flex items-center">
   
      <InputBase
        sx={StyledInputBase}
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        onChange={setInputVal}
        value={inputVal}
        startAdornment={  // Placing the search icon inside the text box on the left side
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </div>
  );
};
export default SearchComp;
