import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const DcfTab = ({ companyData }) => {
  console.log("dcf", companyData);
  const ttmKey = [
    "roe",
    "retentionRatio",
    "growthRate",
    "numberOfDilutedShares",
    "terminalGrowthRate",
    "taxRate",
    "requiredRateOfReturn",

    "eps",
  ];

  const terminalKeys = [
    "growthRate",
    "FCFE2028",
    "Terminalvaluefor2028",
    "Presentvalueofterminalvalue",
    "TotalFCFF",
    "Intrinsicvalueofsecurity",
  ];

  const resultKey = ["CashFlowsprojectedfornextFiveYears"];
  return (
    <div className="flex gap-10">
      <div>
        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#2c3e50]">
                <TableCell className="!text-center" colSpan={2}>
                  TTM Values
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map((row, i) => {
                return (
                  ttmKey.includes(row.key) && (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={row.title === "Roe" ? "uppercase" : ""}
                        component="th"
                      >
                        {row.title}
                      </TableCell>
                      <TableCell>{row.values}</TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#2c3e50]">
                <TableCell className="!text-center" colSpan={2}>
                  Terminal Value calculations
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map((row, i) => {
                return (
                  terminalKeys.includes(row.key) && (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th">
                        {row.key === "growthRate"
                          ? "Growth rate (g)"
                          : row.key === "FCFE2028"
                          ? "2028 FCFE * (1+g)"
                          : row.key === "Terminalvaluefor2028"
                          ? "Terminal value for 2028"
                          : row.key === "Presentvalueofterminalvalue"
                          ? "Present value of terminal value"
                          : row.key === "Intrinsicvalueofsecurity"
                          ? "Intrinsic value of security"
                          : row.title}
                      </TableCell>
                      <TableCell>{row.values}</TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center" colSpan={7}>
                  Cash Flows projected for next 5 years
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map((row, i) => {
                return (
                  resultKey.includes(row.key) &&
                  Object.keys(row.values[0][0]).map((keyItem, keyIndex) => (
                    <TableRow
                      key={keyIndex}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component="th"
                        className={keyIndex === 0 ? "!bg-[#2c3e50]" : ""}
                      >
                        {keyItem}
                      </TableCell>
                      {Array.isArray(row.values[0]) &&
                        row.values[0].map((item, itemIndex) => (
                          <TableCell
                            component="th"
                            key={itemIndex}
                            className={keyIndex === 0 ? "!bg-[#2c3e50]" : ""}
                          >
                            {item[keyItem]}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default DcfTab;
