import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const SharpeTab = ({ companyData }) => {
  const perKey = [
    "Returnfromthemarket",
    "Betaofstock",
    "Riskfreerateofreturn",
    "StandardDeviationofstock",
  ];

  const mesureKey = ["JensensAlpha", "SharpeRatio", "TreynorRatio"];

  return (
    <div className="flex gap-10">
      <div>
        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={2}>
                  Performance measures
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map((row, i) => {
                return (
                  perKey.includes(row.key) && (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={row.title === "Ebit" ? "uppercase" : ""}
                        component="th"
                      >
                        {row.key === "Returnfromthemarket"
                          ? "Return from the market(Rp)"
                          : row.key === "Riskfreerateofreturn"
                          ? "Risk free rate of return (Rf)"
                          : row.key === "StandardDeviationofstock"
                          ? "Standard Deviation of stock (σ)"
                          : row.key === "Betaofstock"
                          ? "Beta of stock (β)"
                          : row.title}
                      </TableCell>
                      <TableCell>{row.values}</TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={2}>
                  Measures
                </TableCell>
                {/* <TableCell align="right">Calories</TableCell>
                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map((row, i) => {
                return (
                  mesureKey.includes(row.key) && (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th">{row.title}</TableCell>
                      <TableCell>{row.values}</TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SharpeTab;
