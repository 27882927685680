import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const MScore = ({ companyData }) => {
  console.log("mscore", companyData);
  return (
    <div className="flex gap-5">
      <TableContainer>
        <Table aria-label="vertical header table" className="border mt-5">
          <TableBody>
            <TableRow>
              <TableCell colSpan={10} className="!text-center !bg-[#2c3e50]">
                M-Score
              </TableCell>
            </TableRow>
            {companyData.map((row, i) => (
              <TableRow
                key={i}
                className={`${
                  row.title === "Piotroski Score" || row.title === "Mscore"
                    ? "!bg-[#2c3e50]"
                    : ""
                }`}
              >
                <TableCell
                  component="th"
                  className={`${
                    row.title === "Mscore" || row.key === "calendarYear"
                      ? "!bg-[#2c3e50]"
                      : "uppercase"
                  }`}
                >
                  {row.key === "calendarYear"
                    ? "Index"
                    : row.title === "Mscore"
                    ? "M-score"
                    : row.title}
                </TableCell>

                {Array.isArray(row.values)
                  ? row.values?.map((rowItem, rowInd) => {
                      console.log("rowkey", row.key);
                      return (
                        <TableCell
                          key={rowInd}
                          className={`!text-center ${
                            row.title === "Mscore" || row.key === "calendarYear"
                              ? "!bg-[#2c3e50]"
                              : "uppercase"
                          }`}
                        >
                          <span
                          // className={`text-white ${
                          //   row.key !== "calendarYear"
                          //     ? rowItem === 8 || rowItem === 9
                          //       ? "bg-green-500 p-3"
                          //       : "bg-red-500 p-3"
                          //     : ""
                          // }`}
                          >
                            {rowItem ? rowItem : "-"}
                          </span>
                        </TableCell>
                      );
                    })
                  : row.values}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table aria-label="vertical header table" className="border mt-5">
          <TableBody>
            <TableRow>
              <TableCell colSpan={10} className="!text-center !bg-[#2c3e50]">
                EXPLANATION
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DSRI</TableCell>
              <TableCell>Day Sales in Receivables Index</TableCell>
              <TableCell>
                The DSRI below 1.031 indicates that earnings are not manipulated
                w.r.t credit terms and if above 1.465 shows that firm has
                changed its credit policies to increase revenue in terms of
                credit.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GMI</TableCell>
              <TableCell>Gross Margin Index</TableCell>
              <TableCell>
                The score of 1.041 or below indicated no earning manipulation
                but a score above 1.193 indicates that gross profit of the
                company may be manipulated.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>AQI</TableCell>
              <TableCell>Asset Quality Index</TableCell>
              <TableCell>
                AQI above 1.0 show that some intangible assets or expenses might
                be capitalized. This means they are converting some expenses to
                long term assets and depreciate over a time.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SGI</TableCell>
              <TableCell>Sales Growth Index</TableCell>
              <TableCell>
                A score more than 1.607 might indicate earning manipulation
                because high growth companies may be motivated to commit fraud.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DEPI</TableCell>
              <TableCell>Depreciation Index</TableCell>
              <TableCell>
                DEPI value above 1.077 shows that asset life is increased upward
                and some assets are revalued.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SGAI</TableCell>
              <TableCell>SG&A Expenses Index</TableCell>
              <TableCell>
                Score above 1.001 shows possiblity of earning manipulation
                because disproportionate increase in sales may not be
                sustainable
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>LVGI</TableCell>
              <TableCell>Leverage Index</TableCell>
              <TableCell>
                The LVGI greater than one indicates that company has taken more
                debt with respect to increase in assets over the period
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TATA</TableCell>
              <TableCell>Total Accruals to Total Assets</TableCell>
              <TableCell>
                Growth in TATA shows that there might be manipulation with
                goodwill or amortization number. Index above .031 might be a
                concern
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MScore;
