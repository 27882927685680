import ReactApexChart from "react-apexcharts";
import { MonthlyReturn } from "../utils/data";
import { useEffect, useState } from "react";
import { MonthlyTable } from "./MonthlyTable";
import axios from "axios";

export const Button = (props) => {
  return (
    <button
      onClick={props.onClick}
      className="bg-[#ffffff13] rounded-lg px-4 py-2 text-sm font-semibold"
    >
      {props.children}
    </button>
  );
};
export const StockMonthly = (props) => {
  const [tab, setTab] = useState(0);
  const [CompData, setCompData] = useState([]);
  const { companyName, companyList } = props;

  const monthlyGraphData = [
    {
      name: CompData.map((item) => item.month),
      data: CompData.map((item) => Number(item.percentageReturn).toFixed(3)),
    },
  ];

  // const monthlyGraphData = CompData.map((item) => {
  //   return {
  //     name: item.month,
  //     data: [Number(item.percentageReturn).toFixed(3)],
  //   };
  // });

  console.log(monthlyGraphData);

  const fetchCompanyData = async () => {
    await axios
      .get(
        `https://company.stockiworld.com/api/percentage-return/m/${companyName?.symbol}/`
      )
      .then((res) => {
        setCompData(res.data[companyName?.symbol]);
      });
  };

  useEffect(() => {
    fetchCompanyData();
  }, [companyName?.symbol]);

  const setTable = () => {
    setTab(0);
  };

  const setChart = () => {
    setTab(1);
  };

  return (
    <div className="h-full flex justify-between flex-col">
      <div className="flex gap-4 justify-end w-full h-[30px] items-center">
        <Button onClick={setTable}>Table</Button>
        <Button onClick={setChart}>Chart</Button>
      </div>
      <div className="w-full p-4 h-[calc(100%-40px)] mx-auto overflow-y-auto overflow-x-auto">
        {tab === 0 ? (
          <MonthlyTable CompData={CompData} />
        ) : (
          <div className="h-full w-full flex gap-4">
            <div className="w-2/4 border-2 border-[#ffffff69] rounded-lg overflow-y-auto p-4">
              <p className="text-lg font-semibold text-white">
                Montly Performance
              </p>
              <table className="w-full mt-4">
                <thead>
                  <tr className="text-sm text-yellow-300 mb-2">
                    <th className="pb-2">Month</th>
                    <th className="pb-2">Open</th>
                    <th className="pb-2">High</th>
                    <th className="pb-2">Low</th>
                    <th className="pb-2">Close</th>
                    <th className="pb-2">Change(%)</th>
                    <th className="pb-2">Avg. Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {CompData.map((item, index) => (
                    <tr className="text-base text-white border-b-[1px] border-[#ffffff13]">
                      <td className="py-2 text-center">{item?.month}</td>
                      <td className="px-2 text-center">{item?.open}</td>
                      <td className="px-2 text-center">{item?.high}</td>
                      <td className="px-2 text-center">{item?.low}</td>
                      <td className="px-2 text-center">{item?.close}</td>
                      <td className="px-2 text-center">
                        {item?.percentageReturn}
                      </td>
                      <td className="px-2 text-center">
                        {Number(item?.average_price).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="h-full w-2/4 overflow-hidden border-2 border-[#ffffff69] rounded-lg p-4">
              <ReactApexChart
                options={MonthlyReturn.options}
                series={monthlyGraphData}
                type="bar"
                height="100%"
                width="100%"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
