import React, { Fragment, useMemo } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Grid,
} from "@mui/material";

const TableComp = ({
  onTouch,
  tableHead,
  calType,
  TableCellSX,
  rows,
  getStyle,
  callStyles,
  parent,
  height = "100vh",
  filterVal = "",
  marketCapFunc,
  paperStyle = {
    height: "80vh",
    overflow: "auto",
    width: "98%",
    // backgroundColor: "#12192c",
    border: "1px solid #aa9999",
    borderRadius: "18px",
    padding: "5px 10px",
    margin: "10px",
    cursor: "pointer",
  },
  children,
}) => {
  const ml5 = useMemo(() => {
    return { marginLeft: "15px" };
  });

  const capitalizeAllText = (text) => text?.toUpperCase();

  const IPOConfirmedCalendarMemo = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell name={index} sx={() => getStyle(index)} scope="row">
              {index + 1}
            </TableCell>
            {/*<TableCell sx={() => getStyle(index)}>{row.symbol}</TableCell>*/}
            {/*<TableCell sx={() => getStyle(index)}>{row.filingDate}</TableCell>*/}
            {/*<TableCell sx={() => getStyle(index)}>{row.effectivenessDate}</TableCell>*/}
            {/*<TableCell sx={() => getStyle(index)}>{row.acceptedDate}</TableCell>*/}
            <TableCell sx={() => getStyle(index)}>{row.company}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.symbol}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.exchange}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.priceRange}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.shares}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.date}</TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows]
  );
  const splitCalendarMemo = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell name={index} sx={() => getStyle(index)} scope="row">
              {index + 1}
            </TableCell>

            <TableCell sx={() => getStyle(index)}>{row.symbol}</TableCell>
            <TableCell sx={() => getStyle(index)}>
              {row.numerator}&nbsp;:&nbsp;{row.denominator}
            </TableCell>
            <TableCell sx={() => getStyle(index)}>{row.date}</TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows]
  );
  const earningCalendarMemo = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover onClick={() => onTouch(row)}>
            <TableCell name={index} sx={() => getStyle(index)} scope="row">
              {index + 1}
            </TableCell>

            <TableCell sx={() => getStyle(index)}>
              <div className="flex items-center">
                <div className="flex items-center">
                  <img
                    src={row?.companyLogo}
                    alt="logo"
                    className="mr-3 w-8 h-8 rounded-full"
                  />
                  <span className="mr-2 text-md">{row?.companyName}</span>
                  <span className="company-symbol text-xs italic">
                    ({row.symbol})
                  </span>
                </div>
              </div>
            </TableCell>
            {/* <TableCell sx={() => getStyle(index)}>{row?.companyName}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.symbol}</TableCell> */}

            <TableCell sx={() => getStyle(index)} colSpan={2}>
              <TableRow sx={{ width: "100%" }}>
                {/* backgroundColor: "#4c2670"  */}
                <TableCell
                  style={{ border: "none" }} // backgroundColor: "#4c2670"
                  sx={() => getStyle("db")}
                >
                  {row.epsEstimated}
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    textAlign: "center",
                    width: "100%",
                    //backgroundColor: "#4c2670",
                  }}
                  sx={() => getStyle("db")}
                >
                  {row.revenueEstimated}
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell
              style={{ backgroundColor: "#0b233e" }}
              sx={() => getStyle(index)}
              colSpan={2}
            >
              <TableRow sx={{ width: "100%" }}>
                <TableCell
                  style={{ border: "none", backgroundColor: "#0b233e" }}
                  sx={() => getStyle(index)}
                >
                  {row.eps}
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "#0b233e",
                  }}
                  sx={() => getStyle(index)}
                >
                  {row.revenue}
                </TableCell>
              </TableRow>
            </TableCell>

            <TableCell sx={() => getStyle(index)}>
              {row.date}
              {"-"}
              {capitalizeAllText(row?.time)}
            </TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows]
  );
  const dividendCalendar = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell name={index} sx={() => getStyle(index)} scope="row">
              {index + 1}
            </TableCell>
            <TableCell
              // style={{display: "flex", alignItems: 'center'}}
              sx={() => getStyle(index)}
            >
              {/*<img src={row.symbol} alt="Company Logo" width={30} className={'mr-2'}/>*/}
              {row.symbol}
            </TableCell>
            <TableCell sx={() => getStyle(index)}>
              {row.declarationDate}
            </TableCell>
            <TableCell sx={() => getStyle(index)}>{row.paymentDate}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.recordDate}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.dividend}</TableCell>

            <TableCell sx={() => getStyle(index)}>{row.adjDividend}</TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows]
  );
  const insiderTradingMemo = useMemo(
    () =>
      rows.map((row, index) => {
        let randomVal = row.acquistionOrDisposition === "A" ? 0 : 1;
        return (
          <Fragment>
            <TableRow key={index} hover>
              <TableCell
                name={index}
                sx={() => callStyles(index, randomVal)}
                scope="row"
              >
                {index + 1}
              </TableCell>
              <TableCell sx={() => callStyles(index, randomVal)}>
                {row.symbol}
              </TableCell>
              <TableCell sx={() => callStyles(index, randomVal)}>
                {row.filingDate}
              </TableCell>
              <TableCell sx={() => callStyles(index, randomVal)}>
                {row.transactionDate}
              </TableCell>
              <TableCell sx={() => callStyles(index, randomVal)}>
                {row.reportingName}
              </TableCell>
              <TableCell sx={() => callStyles(index, randomVal)}>
                {row.typeOfOwner}
              </TableCell>
              <TableCell sx={() => callStyles(index, randomVal)}>
                {row.securitiesOwned}
              </TableCell>
              {/*<TableCell*/}
              {/*    sx={() => callStyles(index, randomVal)}>{row.securitiesTransacted}</TableCell>*/}
              {/*<TableCell*/}
              {/*    sx={() => callStyles(index, randomVal)}>{row.securitiesOwned}</TableCell>*/}
              {/*<TableCell*/}
              {/*    sx={() => callStyles(index, randomVal)}>{row.remaining}</TableCell>*/}
            </TableRow>
          </Fragment>
        );
      }),
    [rows]
  );
  const dailyMarketMovers = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell
              name={index}
              sx={() => callStyles(index, "green")}
              scope="row"
            >
              {index + 1}
            </TableCell>
            <TableCell sx={() => callStyles(index, "green")}>
              {row.code}
            </TableCell>
            <TableCell sx={() => callStyles(index, "green")}>
              {row.symbol}
            </TableCell>
            <TableCell sx={() => callStyles(index, "green")}>
              {row.price}
            </TableCell>
            <TableCell sx={() => callStyles(index, "green")}>
              {row.change}
            </TableCell>
            <TableCell sx={() => callStyles(index, "green")}>
              {row.changePercentage}
            </TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows]
  );
  const dailyMarketLosers = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell name={index} sx={() => callStyles(index, 1)} scope="row">
              {index + 1}
            </TableCell>
            <TableCell sx={() => callStyles(index, 1)}>{row.code}</TableCell>
            <TableCell sx={() => callStyles(index, 1)}>{row.symbol}</TableCell>
            <TableCell sx={() => callStyles(index, 1)}>{row.price}</TableCell>
            <TableCell sx={() => callStyles(index, 1)}>{row.change}</TableCell>
            <TableCell sx={() => callStyles(index, 1)}>
              {row.changePercentage}
            </TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows]
  );
  const mostActive = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell name={index} sx={() => callStyles(index)} scope="row">
              {index + 1}
            </TableCell>
            <TableCell sx={() => callStyles(index)}>{row.code}</TableCell>
            <TableCell sx={() => callStyles(index)}>{row.symbol}</TableCell>
            <TableCell sx={() => callStyles(index)}>{row.price}</TableCell>
            <TableCell sx={() => callStyles(index)}>{row.change}</TableCell>
            <TableCell sx={() => callStyles(index)}>
              {row.changePercentage}
            </TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows]
  );
  const dailySector = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell name={index} sx={() => callStyles(index)} scope="row">
              {index + 1}
            </TableCell>
            <TableCell sx={() => callStyles(index)}>{row.logo}</TableCell>
            <TableCell sx={() => callStyles(index)}>{row.symbol}</TableCell>
            <TableCell sx={() => callStyles(index)}>{row.price}</TableCell>
            <TableCell sx={() => callStyles(index)}>{row.change}</TableCell>
            <TableCell sx={() => callStyles(index)}>
              {row.changePercentage}
            </TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows]
  );
  const sp500Memo = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell name={index} sx={() => getStyle(index)} scope="row">
              {index + 1}
            </TableCell>
            <TableCell sx={() => getStyle(index)}>
              <div className="flex">
                <div>
                  <img src={row.logo_url} alt="Company Logo" width={30} />
                </div>
                <div className="ml-3">
                  <div className="text-base">{row.symbol}</div>
                  <div>{row.name}</div>
                </div>
              </div>
            </TableCell>
            <TableCell sx={() => getStyle(index)}>{row.marketCap}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.sector}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.subSector}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.headQuarter}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.founded}</TableCell>
            <TableCell sx={() => getStyle(index)}>
              {row.dateFirstAdded}
            </TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows, marketCapFunc]
  );
  const dow30Memo = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell name={index} sx={() => getStyle(index)} scope="row">
              {index + 1}
            </TableCell>
            <TableCell sx={() => getStyle(index)}>
              <div className="flex">
                <div>
                  <img src={row.logo_url} alt="Company Logo" width={30} />
                </div>
                <div className="ml-3">
                  <div className="text-base">{row.symbol}</div>
                  <div>{row.name}</div>
                </div>
              </div>
            </TableCell>
            <TableCell sx={() => getStyle(index)}>{row.marketCap}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.sector}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.subSector}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.headQuarter}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.founded}</TableCell>
            <TableCell sx={() => getStyle(index)}>
              {row.dateFirstAdded}
            </TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows, marketCapFunc]
  );
  const nasdaq100Memo = useMemo(
    () =>
      rows.map((row, index) => (
        <Fragment>
          <TableRow key={index} hover>
            <TableCell name={index} sx={() => getStyle(index)} scope="row">
              {index + 1}
            </TableCell>
            <TableCell sx={() => getStyle(index)}>
              <div className="flex">
                <div>
                  <img src={row.logo_url} alt="Company Logo" width={30} />
                </div>
                <div className="ml-3">
                  <div className="text-base">{row.symbol}</div>
                  <div>{row.name}</div>
                </div>
              </div>
            </TableCell>
            <TableCell sx={() => getStyle(index)}>{row.marketCap}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.sector}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.subSector}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.headQuarter}</TableCell>
            <TableCell sx={() => getStyle(index)}>{row.founded}</TableCell>
            <TableCell sx={() => getStyle(index)}>
              {row.dateFirstAdded}
            </TableCell>
          </TableRow>
        </Fragment>
      )),
    [rows, marketCapFunc]
  );
  return parent === "calendar" && calType === "ipo" ? (
    <TableComp
      rows={rows}
      tableHead={
        <TableRow>
          <TableCell className={"items-center"} sx={TableCellSX}>
            S.NO
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            COMPANY
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SYMBOL
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            EXCHANGE
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            PRICE RANGE
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SHARES
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            DATE
          </TableCell>
        </TableRow>
      }
    >
      {IPOConfirmedCalendarMemo}
    </TableComp>
  ) : parent === "calendar" && calType === "split" ? (
    <TableComp
      rows={rows}
      tableHead={
        <TableRow>
          <TableCell className={"items-center"} sx={TableCellSX}>
            Sr.No
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SYMBOL
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            RATIO
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            DATE
          </TableCell>
        </TableRow>
      }
    >
      {splitCalendarMemo}
    </TableComp>
  ) : parent === "calendar" && calType === "earningCalendar" ? (
    <TableComp
      rows={rows}
      tableHead={
        <TableRow>
          <TableCell
            className={"items-center"}
            // style={{ paddingTop: "48px" }}
            sx={TableCellSX}
          >
            S.No
          </TableCell>
          <TableCell
            className={"items-center"}
            // style={{ paddingTop: "48px" }}
            sx={TableCellSX}
          >
            Company
          </TableCell>
          {/* <TableCell
            className={"items-center"}
            // style={{ paddingTop: "48px" }}
            sx={TableCellSX}
          >
            NAME
          </TableCell>
          <TableCell
            className={"items-center"}
            // style={{ paddingTop: "48px" }}
            sx={TableCellSX}
          >
            SYMBOL
          </TableCell> */}

          <TableCell
            className={"items-center"}
            // style={{ backgroundColor: "#4c2670" }}
            sx={TableCellSX}
            colSpan={2}
          >
            <div className={"flex justify-center mb-2"}>ESTIMATE</div>
            <Grid
              container
              spacing={2}
              style={{ display: "grid", gridTemplateColumns: "auto auto" }}
            >
              <Grid item xs={12} style={ml5}>
                EPS
              </Grid>
              <Grid item xs={12} style={ml5}>
                REVENUE
              </Grid>
            </Grid>
          </TableCell>
          <TableCell
            className={"items-center"}
            sx={TableCellSX}
            style={{ backgroundColor: "#161d32" }}
            colSpan={2}
          >
            <div className={"flex justify-center"}>ACTUAL</div>
            <Grid
              container
              spacing={2}
              style={{ display: "grid", gridTemplateColumns: "auto auto" }}
            >
              <Grid item xs={12} style={ml5}>
                EPS
              </Grid>
              <Grid item xs={12} style={ml5}>
                REVENUE
              </Grid>
            </Grid>
          </TableCell>
          <TableCell
            className={"items-center"}
            // style={{ paddingTop: "48px" }}
            sx={TableCellSX}
          >
            DATE TIME
          </TableCell>
        </TableRow>
      }
    >
      {earningCalendarMemo}
    </TableComp>
  ) : parent === "calendar" && calType === "singleView" ? (
    <TableComp
      rows={rows}
      tableHead={
        <TableRow>
          <TableCell
            className={"items-center"}
            // style={{ paddingTop: "48px" }}
            sx={TableCellSX}
          >
            S.No
          </TableCell>
          <TableCell
            className={"items-center"}
            // style={{ paddingTop: "48px" }}
            sx={TableCellSX}
          >
            Com
          </TableCell>

          <TableCell
            className={"items-center"}
            // style={{ backgroundColor: "#4c2670" }}
            sx={TableCellSX}
            colSpan={2}
          >
            <div className={"flex justify-center mb-2"}>ESTIMATE</div>
            <Grid
              container
              spacing={2}
              style={{ display: "grid", gridTemplateColumns: "auto auto" }}
            >
              <Grid item xs={12} style={ml5}>
                EPS
              </Grid>
              <Grid item xs={12} style={ml5}>
                REVENUE
              </Grid>
            </Grid>
          </TableCell>
          <TableCell
            className={"items-center"}
            sx={TableCellSX}
            style={{ backgroundColor: "#161d32" }}
            colSpan={2}
          >
            <div className={"flex justify-center"}>ACTUAL</div>
            <Grid
              container
              spacing={2}
              style={{ display: "grid", gridTemplateColumns: "auto auto" }}
            >
              <Grid item xs={12} style={ml5}>
                EPS
              </Grid>
              <Grid item xs={12} style={ml5}>
                REVENUE
              </Grid>
            </Grid>
          </TableCell>
          <TableCell
            className={"items-center"}
            // style={{ paddingTop: "48px" }}
            sx={TableCellSX}
          >
            DATE TIME
          </TableCell>
        </TableRow>
      }
    >
      {earningCalendarMemo}
    </TableComp>
  ) : parent === "calendar" && calType === "dividendCalendar" ? (
    <TableComp
      rows={rows}
      tableHead={
        <TableRow>
          <TableCell className={"items-center"} sx={TableCellSX}>
            Sr.No
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SYMBOL
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            DECLARATION DATE
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            PAYMENT DATE
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            RECORD DATE
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            DIVIDEND
          </TableCell>

          <TableCell className={"items-center"} sx={TableCellSX}>
            ADJ. DIVIDEND
          </TableCell>
        </TableRow>
      }
    >
      {dividendCalendar}
    </TableComp>
  ) : parent === "trading" && calType === "insiderTrading" ? (
    <TableComp
      rows={rows}
      tableHead={
        <TableRow>
          <TableCell className={"items-center"} sx={TableCellSX}>
            S.NO
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SYMBOL
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            FILE DATE
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            TRADE DATE
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            INSIDER NAME
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            POSITION
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SHARE PRICE
          </TableCell>
          {/*<TableCell className={'items-center'} sx={TableCellSX}>SHARES</TableCell>*/}
          {/*<TableCell className={'items-center'} sx={TableCellSX}>VALUE</TableCell>*/}
          {/*<TableCell className={'items-center'} sx={TableCellSX}>SHARES REMAINING</TableCell>*/}
        </TableRow>
      }
    >
      {insiderTradingMemo}
    </TableComp>
  ) : parent === "trading" && calType === "dailyMarketMovers" ? (
    <Fragment>
      <div className={"text-white text-2xl ml-5 mt-5"}>Daily Gainers</div>
      <TableComp
        rows={rows}
        height={"50vh"}
        tableHead={
          <TableRow>
            <TableCell className={"items-center"} sx={TableCellSX}>
              S.NO
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              LOGO
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              SYMBOL
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              PRICE
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              CHANGE
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              CHANGE%
            </TableCell>
          </TableRow>
        }
      >
        {dailyMarketMovers}
      </TableComp>
      <div className={"text-white text-2xl ml-5 mt-5"}>Daily Losers</div>

      <TableComp
        rows={rows}
        height={"50vh"}
        tableHead={
          <TableRow>
            <TableCell className={"items-center"} sx={TableCellSX}>
              S.NO
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              LOGO
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              SYMBOL
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              PRICE
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              CHANGE
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              CHANGE%
            </TableCell>
          </TableRow>
        }
      >
        {dailyMarketLosers}
      </TableComp>
      <div className={"text-white text-2xl ml-5 mt-5"}>Most Active</div>

      <TableComp
        rows={rows}
        height={"50vh"}
        tableHead={
          <TableRow>
            <TableCell className={"items-center"} sx={TableCellSX}>
              S.NO
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              LOGO
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              SYMBOL
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              PRICE
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              CHANGE
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              CHANGE%
            </TableCell>
          </TableRow>
        }
      >
        {mostActive}
      </TableComp>

      <div className={"text-white text-2xl ml-5 mt-5"}>
        DAILY SECTOR PERFORMANCE
      </div>
      <TableComp
        rows={rows}
        height={"50vh"}
        tableHead={
          <TableRow>
            <TableCell className={"items-center"} sx={TableCellSX}>
              S.NO
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              LOGO
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              SYMBOL
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              PRICE
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              CHANGE
            </TableCell>
            <TableCell className={"items-center"} sx={TableCellSX}>
              CHANGE%
            </TableCell>
          </TableRow>
        }
      >
        {dailySector}
      </TableComp>
    </Fragment>
  ) : parent === "stock" && calType === "sp500" ? (
    <TableComp
      rows={rows}
      tableHead={
        <TableRow>
          <TableCell className={"items-center"} sx={TableCellSX}>
            Sr.No
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SYMBOL
          </TableCell>
          <TableCell
            className={"items-center cursor-pointer"}
            sx={TableCellSX}
            onClick={marketCapFunc}
          >
            MARKET CAP
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SECTOR
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SUB-SECTOR
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            HEAD QUARTER
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            FOUNDED
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            DATE FIRST ADDED
          </TableCell>
        </TableRow>
      }
    >
      {sp500Memo}
    </TableComp>
  ) : parent === "stock" && calType === "dowjones" ? (
    <TableComp
      rows={rows}
      tableHead={
        <TableRow>
          <TableCell className={"items-center"} sx={TableCellSX}>
            Sr.No
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SYMBOL
          </TableCell>
          <TableCell
            className={"items-center cursor-pointer"}
            sx={TableCellSX}
            onClick={marketCapFunc}
          >
            MARKET CAP
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SECTOR
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SUB-SECTOR
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            HEAD QUARTER
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            FOUNDED
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            DATE FIRST ADDED
          </TableCell>
        </TableRow>
      }
    >
      {dow30Memo}
    </TableComp>
  ) : parent === "stock" && calType === "nasdaq" ? (
    <TableComp
      rows={rows}
      tableHead={
        <TableRow>
          <TableCell className={"items-center"} sx={TableCellSX}>
            Sr.No
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SYMBOL
          </TableCell>
          <TableCell
            className={"items-center cursor-pointer"}
            sx={TableCellSX}
            onClick={marketCapFunc}
          >
            MARKET CAP
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SECTOR
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            SUB-SECTOR
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            HEAD QUARTER
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            FOUNDED
          </TableCell>
          <TableCell className={"items-center"} sx={TableCellSX}>
            DATE FIRST ADDED
          </TableCell>
        </TableRow>
      }
    >
      {nasdaq100Memo}
    </TableComp>
  ) : (
    <Paper elevation={3} style={paperStyle}>
      <TableContainer sx={{ height: height }}>
        <Table stickyHeader aria-label="simple table" size="medium">
          <TableHead>{tableHead}</TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default TableComp;
