import { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Typography,
  Paper,
  Box,
  FormControl, Select,
  MenuItem,
  Stack,
  Chip
} from '@mui/material';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
Chart.register(ArcElement, Tooltip, Legend);

const initialData = {
  labels: ['Buy', 'Sale'],
  datasets: [
    {
      data: [0, 0], // Initialize with zero values
      backgroundColor: ['#145939', '#78373f'],
      hoverBackgroundColor: ['#145939', '#78373f'],
    },

  ],
};
// rgb(20, 89, 57)

const ChartPage = ({ data }) => {
  const [selectedInterval, setSelectedInterval] = useState('weekly');
  const [chartData, setChartData] = useState(initialData);
  const [noData, setNoData] = useState(false);



  // Function to filter data by time interval
  const filterDataByInterval = (transactions, interval) => {
    const currentDate = new Date();

    switch (interval) {
      case 'daily':
        return transactions.filter((transaction) => {
          const transactionDate = new Date(transaction.transaction_date);
          return (
            transactionDate.getDate() === currentDate.getDate() &&
            transactionDate.getMonth() === currentDate.getMonth() &&
            transactionDate.getFullYear() === currentDate.getFullYear()
          );
        });
      case 'monthly':
        return transactions.filter((transaction) => {
          const transactionDate = new Date(transaction.transaction_date);
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();
          const transactionYear = transactionDate.getFullYear();
          const transactionMonth = transactionDate.getMonth();

          return (
            transactionYear === currentYear &&
            transactionMonth === currentMonth
          );
        });


      default:
        return transactions;
    }
  };

  useEffect(() => {
    const filteredTransactions = filterDataByInterval(data, selectedInterval);

    // Calculate values for the chart using the filtered data
    const totalBuyValue = filteredTransactions
      .filter((transaction) => transaction.type === 'BUY').length
    // .reduce((total, transaction) => total + transaction.value, 0);

    const totalSaleValue = filteredTransactions
      .filter((transaction) => transaction.type === 'SALE').length
    // .reduce((total, transaction) => total + transaction.value, 0);

    if (totalBuyValue === 0 && totalSaleValue === 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

    // Update the chart data
    setChartData({
      ...initialData,
      datasets: [
        {
          data: [totalBuyValue, totalSaleValue],
          backgroundColor: ['#145939', '#78373f'],
          hoverBackgroundColor: ['#145939', '#78373f'],
        },
      ],
    });
  }, [selectedInterval, data]);

  const handleIntervalChange = (event) => {
    setSelectedInterval(event.target.value);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Insider Trend
      </Typography>

      <Paper elevation={3} sx={{ width: "500px", height: "550px" }}>
        <Box p={2} sx={{ "width": "100%", }}>
          <Stack direction={"row"} sx={{ "width": "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

            <Typography variant="h6" gutterBottom>
              Pie Chart
            </Typography>
            <FormControl>
              {/* <InputLabel>Time Frame</InputLabel> */}
              <Select value={selectedInterval} onChange={handleIntervalChange} size='small'>
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack sx={{
            alignItems: "center",
            display: "flex",
            "justifyContent": "center"
          }}>

            {
              noData && (
                <Box sx={{
                  minWidth: "200px",
                  minHeight: "200px",
                  maxWidth: "400px",
                  maxHeight: "400px",
                  alignItems: "center",
                  display: "flex",
                  "justifyContent": "center"
                }}>
                  <Chip label="No Data Available" color="warning" sx={{ color: "black", fontSize: "14px" }} />
                </Box>
              )
            }
            {
              !noData && (
                <Pie data={chartData} />
              )
            }
          </Stack>

        </Box>

      </Paper>
    </div>
  );
};

export default ChartPage