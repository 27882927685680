import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import {
  CashBurnChartData,
  DailyReturn,
  RelativeValuationChartData,
} from "../StockFundamentals/PopularStocks/utils/data";

const RatioComponent = ({ companyData }) => {
  console.log("Ratio", companyData);

  const firstTable = [
    "period",
    "calendarYear",
    "revenue",
    "grossProfit",
    "operatingIncome",
    "interestExpense",
    "incomeBeforeTax",
    "netIncome",
    "cashAndCashEquivalents",
    "shortTermInvestments",
    "",
    "inventory",
    "totalCurrentAssets",
    "totalAssets",
    "accountPayables",
    "",
    "longTermDebt",
    "totalCurrentLiabilities",
    "totalStockholdersEquity",
    "purchases",
  ];

  const liquidityRatios = ["currentRatio", "quickRatio", "cashRatio"];

  const profitabilityRatio = [
    "grossProfitMargin",
    "operatingProfitMargin",
    "pretaxMargin",
    "netProfitMargin",
    "returnonEquity",
  ];

  const solvencyRation = ["debtToEquity", "interestCoverageratio"];

  const turnoverRatio = [
    "receivablesTurnover",
    "daysOfSalesOutstanding",
    "inventoryTurnover",
    "daysOfInventoryOnHand",
    "PayablesTurnover",
    "NumberOfDaysPayablesTurnover",
    "CashConversionCycle",
  ];

  let graphData;
  let graphCompData;

  companyData?.map((it) => {
    console.log(it.key === "pe" ? (graphData = it.values) : "def");
  });

  companyData?.map((it) => {
    console.log(it.key === "companyName" ? (graphCompData = it.values) : "def");
  });
  const compGraphData = [
    {
      name: graphCompData,
      data: graphData,
    },
  ];

  console.log("compGraphData", compGraphData);

  console.log(RelativeValuationChartData(companyData));

  return (
    <div>
      <div>
        <TableContainer>
          <Table aria-label="vertical header table" className="border mt-5">
            <TableBody>
              {companyData.map(
                (row, i) =>
                  firstTable.includes(row.key) && (
                    <TableRow
                      key={i}
                      className={`${
                        row.title === "Piotroski Score" ||
                        row.title === "Mscore"
                          ? "bg-[#011219]"
                          : ""
                      }`}
                    >
                      <TableCell component="th">{row.title}</TableCell>

                      {Array.isArray(row.values)
                        ? row.values?.map((rowItem, rowInd) => {
                            return (
                              <TableCell key={rowInd} className="!text-center ">
                                <span>{rowItem ? rowItem : "-"}</span>
                                <div>{row.year && row.year[rowInd]}</div>
                                {row.ratio && (
                                  <div
                                    className={`${
                                      row.ratio[rowInd] > 0
                                        ? "text-green-500"
                                        : "text-red-500"
                                    }`}
                                  >
                                    {row.ratio[rowInd]
                                      ? row.ratio[rowInd] > 0
                                        ? `+${row.ratio[rowInd]}%`
                                        : `${row.ratio[rowInd]}%`
                                      : "-"}
                                  </div>
                                )}
                              </TableCell>
                            );
                          })
                        : row.values}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <TableContainer>
          <Table aria-label="vertical header table" className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={1}>
                  Liquidity Ratios
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map(
                (row, i) =>
                  liquidityRatios.includes(row.key) && (
                    <TableRow
                      key={i}
                      className={`${
                        row.title === "Piotroski Score" ||
                        row.title === "Mscore"
                          ? "bg-[#011219]"
                          : ""
                      }`}
                    >
                      <TableCell component="th">{row.title}</TableCell>

                      {Array.isArray(row.values)
                        ? row.values?.map((rowItem, rowInd) => {
                            return (
                              <TableCell key={rowInd} className="!text-center ">
                                <span>{rowItem ? rowItem : "-"}</span>
                                <div>{row.year && row.year[rowInd]}</div>
                                {row.ratio && (
                                  <div
                                    className={`${
                                      row.ratio[rowInd] > 0
                                        ? "text-green-500"
                                        : "text-red-500"
                                    }`}
                                  >
                                    {row.ratio[rowInd]
                                      ? row.ratio[rowInd] > 0
                                        ? `+${row.ratio[rowInd]}%`
                                        : `${row.ratio[rowInd]}%`
                                      : "-"}
                                  </div>
                                )}
                              </TableCell>
                            );
                          })
                        : row.values}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <TableContainer>
          <Table aria-label="vertical header table" className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={1}>
                  Profitability ratio
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map(
                (row, i) =>
                  profitabilityRatio.includes(row.key) && (
                    <TableRow
                      key={i}
                      className={`${
                        row.title === "Piotroski Score" ||
                        row.title === "Mscore"
                          ? "bg-[#011219]"
                          : ""
                      }`}
                    >
                      <TableCell component="th">{row.title}</TableCell>

                      {Array.isArray(row.values)
                        ? row.values?.map((rowItem, rowInd) => {
                            return (
                              <TableCell key={rowInd} className="!text-center ">
                                <span>{rowItem ? rowItem : "-"}</span>
                                <div>{row.year && row.year[rowInd]}</div>
                                {row.ratio && (
                                  <div
                                    className={`${
                                      row.ratio[rowInd] > 0
                                        ? "text-green-500"
                                        : "text-red-500"
                                    }`}
                                  >
                                    {row.ratio[rowInd]
                                      ? row.ratio[rowInd] > 0
                                        ? `+${row.ratio[rowInd]}%`
                                        : `${row.ratio[rowInd]}%`
                                      : "-"}
                                  </div>
                                )}
                              </TableCell>
                            );
                          })
                        : row.values}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <TableContainer>
          <Table aria-label="vertical header table" className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={1}>
                  Solvency ratio
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map(
                (row, i) =>
                  solvencyRation.includes(row.key) && (
                    <TableRow
                      key={i}
                      className={`${
                        row.title === "Piotroski Score" ||
                        row.title === "Mscore"
                          ? "bg-[#011219]"
                          : ""
                      }`}
                    >
                      <TableCell component="th">{row.title}</TableCell>

                      {Array.isArray(row.values)
                        ? row.values?.map((rowItem, rowInd) => {
                            return (
                              <TableCell key={rowInd} className="!text-center ">
                                <span>{rowItem ? rowItem : "-"}</span>
                                <div>{row.year && row.year[rowInd]}</div>
                                {row.ratio && (
                                  <div
                                    className={`${
                                      row.ratio[rowInd] > 0
                                        ? "text-green-500"
                                        : "text-red-500"
                                    }`}
                                  >
                                    {row.ratio[rowInd]
                                      ? row.ratio[rowInd] > 0
                                        ? `+${row.ratio[rowInd]}%`
                                        : `${row.ratio[rowInd]}%`
                                      : "-"}
                                  </div>
                                )}
                              </TableCell>
                            );
                          })
                        : row.values}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <TableContainer>
          <Table aria-label="vertical header table" className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={1}>
                  Turnover Ratio
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map(
                (row, i) =>
                  turnoverRatio.includes(row.key) && (
                    <TableRow
                      key={i}
                      className={`${
                        row.title === "Piotroski Score" ||
                        row.title === "Mscore"
                          ? "bg-[#011219]"
                          : ""
                      }`}
                    >
                      <TableCell component="th">{row.title}</TableCell>

                      {Array.isArray(row.values)
                        ? row.values?.map((rowItem, rowInd) => {
                            return (
                              <TableCell key={rowInd} className="!text-center ">
                                <span>{rowItem ? rowItem : "-"}</span>
                                <div>{row.year && row.year[rowInd]}</div>
                                {row.ratio && (
                                  <div
                                    className={`${
                                      row.ratio[rowInd] > 0
                                        ? "text-green-500"
                                        : "text-red-500"
                                    }`}
                                  >
                                    {row.ratio[rowInd]
                                      ? row.ratio[rowInd] > 0
                                        ? `+${row.ratio[rowInd]}%`
                                        : `${row.ratio[rowInd]}%`
                                      : "-"}
                                  </div>
                                )}
                              </TableCell>
                            );
                          })
                        : row.values}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default RatioComponent;
