import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from '../../assets/images/img1.jpg'
import img2 from '../../assets/images/img2.jpg'
import img3 from '../../assets/images/img3.jpg'
import img4 from '../../assets/images/img4.jpg'

export default function Section() {


  const sectionsData = [
    {
      title: 'EXCHANGE UNION ',
      percentage1: '-0.33',
      percentage2: '8.16',
      percentage3: '4.02 %',
      amount: '$16,322,520.00',
      image: img1
    },
    {
      title: 'RIPPLE ',
      percentage1: '-0.14',
      percentage2: '8.16',
      percentage3: '11.5 %',
      amount: '$16,322,520.00',
      image: img2
    },
    {
      title: 'VERITASEUM ',
      percentage1: '-46.70',
      percentage2: '8.16',
      percentage3: '13.84 %',
      amount: '$16,322,520.00',
      image: img3
    },
    {
      title: 'DIGITALCOIIN ',
      percentage1: '-0.01',
      percentage2: '8.16',
      percentage3: '14.89 %',
      amount: '$1,986,979.00',
      image: img4
    },
    {
      title: 'DIGITALCOIIN 2',
      percentage1: '-0.01',
      percentage2: '8.16',
      percentage3: '14.89 %',
      amount: '$1,986,979.00',
      image: img4
    },
  ];

  return (
    <div className='bg-white'>
      <div className="container max-w-screen-xl mx-auto bg-white text-black">
        {/* <div className='bg-white text-black flex flex-col sm:flex-row items-center justify-center gap-8'> */}
          <Swiper
            spaceBetween={50}
            slidesPerView={4}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
          {sectionsData?.map((section, index) => (
            <SwiperSlide key={index}>
            <div  className='flex flex-col sm:flex-row items-center justify-center border-r-2 border-black-500 pl-4'>
              <div className=' mb-4 sm:mb-0'>
                <img src={section.image} alt='Image Alt Text' className='' />
              </div>
              <div className='pt-5 pb-5 pr-5 pl-1 font-bold'>
                <h2>{section.title} <span className="text-green-500 ">{section.percentage1}</span></h2>
                <h2>
                  <span className="text-black-800">${section.percentage2}</span> - <span className="text-red-500">{section.percentage3}</span>
                </h2>
                <h2>{section.amount}</h2>
              </div>
            </div>
            </SwiperSlide>
          ))}
          </Swiper>
        {/* </div> */}
      </div>
    </div>

  )
}
