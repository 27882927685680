import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFinanceDataAPI } from "../../api/finance/get-data";

export const getFinanceData = createAsyncThunk(
  "get_financial_data",
  async (symbol) => {
    const response = await getFinanceDataAPI(symbol);
    return response;
  },
);


const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  errorMessage: "",
};

export const FinanceSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    setIsLoadingForDataFetch: (state, action) => {
      state.isLoading = action.payload;
    },

    setError: (state, action) => {
      state.isError = action.payload.isError;
      state.errorMessage = action.payload.isError ? action.payload.message : "";
    },

    resetData: (state) => {
      state.data = []
      state.isError = false
      state.errorMessage=  ""
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFinanceData.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getFinanceData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.data = action.payload.data?.data;
        state.isLoading = false;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload["Error Message"] || action.payload.message;
      }
    });
  },
});
export const { setIsLoadingForDataFetch, setError, resetData } = FinanceSlice.actions;
export default FinanceSlice.reducer;
