import generalMiddleware from "../middleware/middleware";
import { Response } from "../middleware/format-response";

export const getFinanceDataAPI = async (symbol) =>
  await generalMiddleware.request({
    url: "/financial/",
    method: "GET",
    params: {
      symbol
    }
  });
