export const formatValue = (data) => {
  const formattedData = data?.map((value) => {
    if (typeof value === "number") {
      if (value >= 1e9) {
        return (value / 1e9).toFixed(1) + " B";
      } else if (value >= 1e6) {
        return (value / 1e6).toFixed(1) + " M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(1) + " T";
      }
    } else if (typeof value === "string") {
      if (value.includes("Billion")) {
        return value.replace(" Billion", " B");
      } else if (value.includes("Million")) {
        return value.replace(" Million", " M");
      } else if (value.includes("Thousand")) {
        return value.replace(" Thousand", " Th");
      } else if (value.includes("Trillion")) {
        return value.replace(" Trillion", " T");
      }
    }
    return value;
  });

  return formattedData;
};
