import React from "react";
import { Grid, InputBase, Typography } from "@mui/material";
import TopCircleChart from "./TopCircleChart";

const SearchCard = (props) => {
  const topSearchData = props.searchData[0];
  const priceChange = parseFloat(topSearchData?.price_change || 0);
  const percentChange = parseFloat(topSearchData?.price_change_percentage || 0);

  const dataObj = {
    circleColour: priceChange > 0 ? "#00DE4C" : "#DE0000",
    bgColor: priceChange > 0 ? "#004B19" : "#4B0000",
  };

  return (
    <Grid container spacing={2} style={{ height: "100%" }}>
    <Grid item md={10} xs={10} sm={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} className="m-2">
          <img
            style={{ maxWidth: "30%", maxHeight: "30%" }}
            src="https://www.freepnglogos.com/uploads/tesla-logo-png-27.png"
            alt="logo"
          />
          <div className="p-2">
            <Typography className="text-bold text-lg">{topSearchData?.Symbol}</Typography>
            <Typography className="text-xs">
              ${topSearchData?.price.toFixed(2)}
            </Typography>
            <Typography className="text-xs" style={{ color: dataObj.circleColour }}>
              {`${priceChange.toFixed(2)} (${percentChange.toFixed(2)}%)`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TopCircleChart item={dataObj} topSearchData={topSearchData} style={{ width: "40%", height: "auto" }} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item md={2} xs={2} sm={6}>
      <InputBase
        className="text-primary width-100 text-center"
        fullWidth
        sx={{ backgroundColor: "white", borderRadius: "4px", px: 1 }}
        placeholder="Search"
      />
    </Grid>
  </Grid>
  
  );
};

export default SearchCard;
