import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import {
  CashBurnChartData,
  DailyReturn,
  RelativeValuationChartData,
} from "../StockFundamentals/PopularStocks/utils/data";

const RelativeValuation = ({ companyData }) => {
  console.log("RelativeValuation", companyData);

  const compKey = ["companyName"];

  const marketKey = ["priceShare", "marketCap", "ev"];

  const financialKey = ["revenue", "ebitda", "ebit", "netIncome", "bookValue"];

  const valuationKey = ["evSales", "evEbitda", "evEbit", "pe", "pb"];

  let graphData;
  let graphCompData;

  companyData?.map((it) => {
    console.log(it.key === "pe" ? (graphData = it.values) : "def");
  });

  companyData?.map((it) => {
    console.log(it.key === "companyName" ? (graphCompData = it.values) : "def");
  });
  const compGraphData = [
    {
      name: graphCompData,
      data: graphData,
    },
  ];

  console.log("compGraphData", compGraphData);

  console.log(RelativeValuationChartData(companyData));

  return (
    <div>
      <div className="flex gap-5 px-6">
        <TableContainer className="!w-[150px]">
          <Table className="mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell
                  className="!text-center !text-[#161a20]"
                  colSpan={11}
                >
                  {" s"}
                </TableCell>
              </TableRow>
              <TableRow className="!bg-[#161a20]">
                {companyData?.map(
                  (row, i) =>
                    compKey.includes(row.key) && (
                      <TableCell key={i} className="truncate">
                        {row.title}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(companyData?.[0]?.values) &&
                companyData?.[0]?.values.map((_, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {companyData?.map(
                        (item, itemIndex) =>
                          compKey.includes(item.key) && (
                            <TableCell key={itemIndex} className="truncate">
                              {item.values[i]}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer className="!w-[400px]">
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={11}>
                  Market Data
                </TableCell>
              </TableRow>
              <TableRow className="!bg-[#161a20]">
                {companyData?.map(
                  (row, i) =>
                    marketKey.includes(row.key) && (
                      <TableCell key={i} className="truncate">
                        {row.key === "ev"
                          ? "EV"
                          : row.key === "priceShare"
                          ? "Price/share (P)"
                          : row.title}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(companyData?.[0]?.values) &&
                companyData?.[0]?.values.map((_, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {companyData?.map(
                        (item, itemIndex) =>
                          marketKey.includes(item.key) && (
                            <TableCell key={itemIndex} className="truncate">
                              {item.values[i]}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer className="!w-2/5">
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={11}>
                  Financial Data
                </TableCell>
              </TableRow>
              <TableRow className="!bg-[#161a20]">
                {companyData?.map(
                  (row, i) =>
                    financialKey.includes(row.key) && (
                      <TableCell key={i}>
                        {row.key === "ebitda"
                          ? "EBITDA"
                          : row.key === "ebit"
                          ? "EBIT"
                          : row.title}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(companyData?.[0]?.values) &&
                companyData?.[0]?.values.map((_, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {companyData?.map(
                        (item, itemIndex) =>
                          financialKey.includes(item.key) && (
                            <TableCell key={itemIndex} className="truncate">
                              {item.values[i]}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer className="!w-[480px]">
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={11}>
                  Valuation
                </TableCell>
              </TableRow>
              <TableRow className="!bg-[#161a20]">
                {companyData?.map(
                  (row, i) =>
                    valuationKey.includes(row.key) && (
                      <TableCell key={i} className="truncate">
                        {row.key === "evSales"
                          ? "EV/Sales"
                          : row.key === "evEbitda"
                          ? "EV/EBITDA"
                          : row.key === "evEbit"
                          ? "EV/EBIT"
                          : row.key === "pe"
                          ? "P/E"
                          : row.key === "pb"
                          ? "P/B"
                          : row.title}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(companyData?.[0]?.values) &&
                companyData?.[0]?.values.map((_, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {companyData?.map(
                        (item, itemIndex) =>
                          valuationKey.includes(item.key) && (
                            <TableCell key={itemIndex}>
                              {Number(item.values[i]).toFixed(2)}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="h-[48%] w-full px-4 pb-3 border-2 border-[#ffffff37] rounded-md">
        {/* <ReactApexChart
          options={RelativeValuation(companyData).options}
          series={RelativeValuation(companyData).series}
          type="bar"
          height="100%"
          width="100%"
        /> */}
      </div>
    </div>
  );
};

export default RelativeValuation;
