import Navbar from './navbar'
import Slider from './slider'
import News from './news'
// Import Swiper styles
import 'swiper/css';

export default function PharmbizznewComponent() {

  return (
    <>
      <Navbar />
      <Slider />
      <News />
    </>
  )
}
