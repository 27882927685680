import React, { Fragment, useMemo } from "react";

import { Box, TableCell, TableRow } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState } from "react";

import DrawerHeader from "../../../DrawerHeader/DrawerHeader";
import Navbar from "../../../Navbar/Navbar";
import TableComp from "../../ReUsableComps/TableComp";
import { useCallback } from "react";

export default function SingleTable() {
  const location = useLocation();
  const data = location.state;
  const [companyData, setCompanyData] = useState([data]);
  
  const TableCellStyle = useMemo(() => {
    return {
      fontWeight: 400,
      color: "white",
    };
  }, []);

   const getStyle = useCallback((index) => {
    return { ...TableCellStyle, backgroundColor: "#inherit" }; //index % 2 === 0 ? '#002147' : '#12192c'
  }, []);

 const TableCellSX = useMemo(() => {
    return {
      // backgroundColor: "#12192c",
      fontWeight: 400,
      color: "white",
    };
  }, []);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbar />
        <Box
          component="main"
          className="bg-primary"
          sx={{
            flexGrow: 1,
            paddingBottom: "6px",
            paddingTop: "4px",
            height: "auto",
            overflowY: "scroll",
          }}
        >
          <DrawerHeader />
          <TableComp
            calType={"singleView"}
            TableCellSX={TableCellSX}
            rows={companyData}
            getStyle={getStyle}
            parent={"calendar"}
            paperStyle={{
              height: "80vh",
              overflow: "auto",
              width: "50%",
              backgroundColor: "#12192c",
            }}
          />
        </Box>
      </Box>
    </>
  );
}
