import React, { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import logo from '../../assets/images/logo.jpg'
import logo2 from '../../assets/images/logo2.jpg'
import PHARMBIZZ from '../../assets/images/PHARMBIZZ.jpg'

export default function Section2() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [modeldata, setModelData] = useState(null)

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       ''
  //     );
  //     const data = response.data;
  //     console.log('response', response)

  //     setModelData(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  console.log("datadata", modeldata)


  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const data = [
    {
      logo: logo,
      name: "Hetro",
      title: 'Hetero Enters into a Licensing Agreement With Gilead Science, Inc. for the Manufacturing and Distribution of "Remdesivir" in 127 Countries, Including India, for COVID-19',
      description: "The company's newest Studio aims to reinvent and transform organizations within the healthcare, agriculture, and pharmaceutical industries The company's newest Studio aims to reinvent and transform organizations within the healthcare, agriculture, and pharmaceutical industries The company's newest Studio aims to reinvent and transform organizations within the healthcare, agriculture, and pharmaceutical industries",
    },
    {
      logo: logo2,
      name: "Robuvit",
      title: "Starlight Children's Foundation Announces 2020 Design-A-Gown Finalists",
      description: "The company's newest Studio aims to reinvent and transform organizations within the healthcare, agriculture, and pharmaceutical industries",
    },
  ];

  const newsItems = [
    {
      title: 'News-1',
      description:
        'Hetero Enters into a Licensing Agreement With Gilead Science, Inc. for the Manufacturing and Distribution of "Remdesivir"',
    },
    {
      title: 'News-3',
      description:
        'Hetero Enters into a Licensing Agreement With Gilead Science, Inc. for the Manufacturing and Distribution of "Remdesivir"',
    },
    {
      title: 'News-2',
      description:
        'Hetero Enters into a Licensing Agreement With Gilead Science, Inc. for the Manufacturing and Distribution of "Remdesivir"',
    },
    {
      title: 'News-4',
      description:
        'Hetero Enters into a Licensing Agreement With Gilead Science, Inc. for the Manufacturing and Distribution of "Remdesivir"',
    },
    
  ];

  return (
    <div className=" bg-white text-black p-8">
      <div className='container max-w-screen-xl mx-auto bg-white text-black flex flex-col md:flex-row'>
        <div className='md:w-1/3'>
          <div className=''>
            {selectedImage ? (
              <img src={selectedImage} alt='Uploaded Image' className='w-full' />
            ) : (
              <img src={PHARMBIZZ} alt='Default Image' className='w-full' />
            )}
          </div>
          <input type='file' accept='image/*' onChange={handleImageChange} />
          <div className='mt-5 flex flex-wrap justify-between items-center '>
            <div className='mb-4   md:w-auto rounded-full ' style={{ border: '8px solid #c3b8b838' }}>
              <div className='flex justify-center items-center w-20 h-20 border border-green rounded-full' style={{ border: "5px solid", borderColor: 'green green transparent' }}>
                <h1 className='font-bold text-lg md:text-xl'>70%</h1>
              </div>
            </div>
            <div className='mb-4  rounded-full md:w-auto' style={{ border: '8px solid #c3b8b838' }}>
              <div className='flex justify-center items-center w-20 h-20 border border-green rounded-full' style={{ border: "5px solid", borderColor: 'green green transparent transparent' }}>
                <h1 className='font-bold text-lg md:text-xl'>50%</h1>
              </div>
            </div>
            <div className='mb-4  rounded-full md:w-auto' style={{ border: '8px solid #c3b8b838' }}>
              <div className='flex justify-center items-center w-20 h-20 border border-black rounded-full' style={{ border: "5px solid", borderColor: 'red transparent transparent' }}>
                <h1 className='font-bold text-lg md:text-xl'>30%</h1>
              </div>
            </div>
            <div className='mb-4  rounded-full md:w-auto' style={{ border: '8px solid #c3b8b838' }}>
              <div className='flex justify-center items-center w-20 h-20 border border-black rounded-full' style={{ border: "5px solid", borderColor: 'transparent transparent transparent red' }}>
                <h1 className='font-bold text-lg md:text-xl'>20%</h1>
              </div>
            </div>
          </div>
        </div>


        <div className='md:w-1/3'>
          {data?.map((item, index) => (
            <div className='max-w-md px-8 ' key={index}>
              <div className='border-b'>
                <img src={item.logo} alt='Logo 1' />
              </div>
              <h3 className='font-bold'>{item.title}</h3>
              <p className='pt-4 text-xs'>{item.description}</p>
              <p className='pt-4'>
                <button
                  onClick={() => openModal(item)}
                  className='cursor-pointer text-gray'
                >
                  Know more <ArrowForwardIcon fontSize='5px' color='gray' />
                </button>
              </p>
            </div>
          ))}
        </div>

        <div className='md:w-1/3'>
        <h1 className='font-bold text-xl px-8 text-red-500'>Top News</h1>
        {newsItems?.map((item, index) => (
          <div className='max-w-md px-8 mt-5 '>
            <h3 className='font-bold'> {item.title}</h3>
            <p className='pt-2 text-xs'>{item.description} </p>
          </div>
           ))}
        </div>
      </div>

      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        maxWidth="md"
        fullWidth
      >
        <div className='bg-white'>

        <DialogTitle>{selectedItem && selectedItem.name}</DialogTitle>
        <DialogContent>
          {selectedItem && (
            <>
              <img src={selectedItem.logo} alt='Logo' />
              <p>{selectedItem.description}</p>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
