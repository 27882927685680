import React, { useState } from "react";
import { Box, Button, Grid, Tab } from "@mui/material";
// import { toast } from "react-toastify";
// import axios from "axios";

import Navbar from "../../Components/Navbar/Navbar";
import "./style.scss";
import DrawerHeader from "../../Components/DrawerHeader/DrawerHeader";
import Loader from "../../Components/Loader/Loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import InsiderTradingComponent from "../../Components/InsiderTradingComponent/InsiderTrading";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ParentComponent from "../../Components/AiDocInsights/ReUsableComps/ParentComponent";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});
// import { BASE_URL } from "../../constants/constant";

const InsiderTrading = () => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState("1");

    // useEffect(() => {
    //   const getPieChartData = async () => {
    //     try {
    //       const response = await axios.post(
    //         BASE_URL + "/upgrades-downgrades-consensus",
    //         {
    //           symbol: "AAPL",
    //         }
    //       );

    //       setPieChartData(response.data.data);
    //     } catch (error) {
    //       toast.error(error.response.data.message);
    //     }

    //     setLoading(false);
    //   };

    //   getPieChartData();
    // }, []);

    return (
        <ParentComponent>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <InsiderTradingComponent />
            </ThemeProvider>
        </ParentComponent>
    );
};

export default InsiderTrading;
