import React from 'react';

const WeightegeTable = () => {
    const data = [
        { item: 'Item 1', weight: 0.2 },
        { item: 'Item 2', weight: 0.3 },
        { item: 'Item 3', weight: 0.5 },
        { item: 'Item 1', weight: 0.2 },
        { item: 'Item 2', weight: 0.3 },
        { item: 'Item 3', weight: 0.5 },
      ];
  return (
    <table className="table w-full bg-gray-800 text-white p-4">
      <thead>
        <tr>
          <th className='text-left p-5'>Item</th>
          <th className='text-left p-5'>Weight</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className={`bg-${index % 2 === 0 ? 'blue' : 'green'}-500 text-black`}>
            <td className='p-5'>{item.item}</td>
            <td className='p-5'>{item.weight}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WeightegeTable;
