export const MonthlyTable = (props) => {
  const { CompData } = props;
  return (
    <div className="border-2 border-[#ffffff69] rounded-lg p-4">
      <h2 className="text-base font-semibold">Monthly Return</h2>
      <div className="mt-2 px-1 w-full">
        <table className="mt-2 w-full">
          <thead>
            <tr className="text-sm text-white bg-blue-500 mb-2">
              <th className="pr-4">2010</th>
              <th className="pr-4">2011</th>
              <th className="pr-4">2012</th>
              <th className="pr-4">2013</th>
              <th className="pr-4">2014</th>
              <th className="pr-4">2015</th>
              <th className="pr-4">2016</th>
              <th className="pr-4">2017</th>
              <th className="pr-4">2018</th>
              <th className="pr-4">2019</th>
              <th className="pr-4">2020</th>
              <th className="pr-4">2021</th>
              <th className="pr-4">2022</th>
              <th className="pr-4">2023</th>
              <th className="pr-4"> </th>
              <th className="pr-4">Max</th>
              <th className="pr-4">Min</th>
              <th className="pr-4">Avg</th>
            </tr>
          </thead>
          <tbody>
            {CompData.map((item) => {
              return (
                <tr className="text-sm text-[#ffffff75] border-b-[1px] border-[#ffffff13]">
                  <td className="py-4 px-4"></td>
                  <td className="px-0 bg-[#e35d5dc6] text-white">-9.50%</td>
                  <td className="px-4 bg-[#5de364b6] text-white">10.75%</td>
                  <td className="px-4 bg-[#5de364b6] text-white">1.79%</td>
                  <td className="px-4 bg-[#5de364b6] text-white">20.60%</td>
                  <td className="px-4 bg-[#e35d5dc6] text-white">-8.46%</td>
                  <td className="px-4 bg-[#e35d5dc6] text-white">-20.34%</td>
                  <td className="px-4 bg-[#5de364b6] text-white">17.90%</td>
                  <td className="px-4 bg-[#5de364b6] text-white">13.80%</td>
                  <td className="px-4 bg-[#e35d5dc6] text-white">-7.75%</td>
                  <td className="px-4 bg-[#5de364b6] text-white">55.52%</td>
                  <td className="px-4 bg-[#5de364b6] text-white">12.45%</td>
                  <td className="px-4 bg-[#e35d5dc6] text-white">-11.36%</td>
                  <td className="px-4 bg-[#5de364b6] text-white">40.62%</td>
                  <td className="px-4 bg-blue-500 text-white">{item.month}</td>
                  <td className="px-4 bg-[#5de364b6] text-white">
                    {Number(item.high).toFixed(2)}%
                  </td>
                  <td className="px-4 bg-[#e35d5dc6] text-white">
                    {Number(item.low).toFixed(2)}%
                  </td>
                  <td className="px-4 bg-[#5de364b6] text-white">
                    {Number(item.average_price).toFixed(2)}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
