import ReactApexChart from "react-apexcharts";
import { DailyReturn, DailyReturnIndex } from "../utils/data";
import { useEffect, useState } from "react";
import axios from "axios";

export const StockDaily = (props) => {
  const { companyName, companyList } = props;
  const [spyCompData, setSpyCompData] = useState([]);
  const [qqqCompData, setQqqCompData] = useState([]);
  const [CompData, setCompData] = useState([]);

  const fetchSPYData = async () => {
    await axios
      .get(`https://company.stockiworld.com/api/percentage-return/d/SPY/`)
      .then((res) => {
        setSpyCompData(res.data.SPY);
      });
  };
  const fetchQQQData = async () => {
    await axios
      .get(`https://company.stockiworld.com/api/percentage-return/d/QQQ/`)
      .then((res) => {
        setQqqCompData(res.data.QQQ);
      });
  };
  const fetchCompanyData = async () => {
    await axios
      .get(
        `https://company.stockiworld.com/api/percentage-return/d/${companyName?.symbol}/`
      )
      .then((res) => {
        setCompData(res.data[companyName?.symbol]);
      });
  };
  useEffect(() => {
    fetchSPYData();
    fetchQQQData();
  }, []);

  useEffect(() => {
    fetchCompanyData();
  }, [companyName?.symbol]);

  const compGraphData = [
    {
      name: companyName?.symbol,
      data: CompData.map((item, index) => [
        item.date ? item.date : "2023-08-01",
        Number(item.percentageReturn).toFixed(3),
      ]),
    },
  ];

  const dailyGraphData = [
    {
      name: companyName.symbol,
      data: CompData.map((item) => Number(item.percentageReturn).toFixed(3)),
    },
    {
      name: "SPY",
      data: spyCompData.map((item) => Number(item.percentageReturn).toFixed(3)),
    },
    {
      name: "QQQ",
      data: qqqCompData.map((item) => Number(item.percentageReturn).toFixed(3)),
    },
  ];

  return (
    <div className="flex text-sm h-full justify-between">
      <div className="flex gap-2 h-full w-1/3">
        <div className="border-2 border-[#ffffff37] w-full rounded-lg overflow-y-auto p-4">
          <h2 className="text-xl font-semibold">Compare</h2>
          <table className="w-full mt-4">
            <thead>
              <tr className="text-base text-yellow-400 mb-2 text-left">
                <th className="py-2">Date</th>
                <th className="px-2">Day</th>
                <th className="px-2">{companyName?.symbol}</th>
                <th className="px-2">SPY</th>
                <th className="px-2">QQQ</th>
              </tr>
            </thead>
            <tbody className="">
              {spyCompData.map((item, index) => (
                <tr className="text-base text-white border-b-[1px] border-[#ffffff13]">
                  <td className="py-2">{item?.date}</td>
                  <td className="px-2">{item?.day}</td>
                  <td className="px-2">
                    {Number(CompData[index]?.percentageReturn).toFixed(2)}%
                  </td>
                  <td className="px-2">
                    {Number(item?.percentageReturn).toFixed(2)}%
                  </td>
                  <td className="px-2">
                    {Number(qqqCompData[index]?.percentageReturn).toFixed(2)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col gap-2 h-full justify-between w-3/5">
        <div className="h-[48%] w-full px-4 pb-3 border-2 border-[#ffffff37] rounded-md">
          <ReactApexChart
            options={DailyReturn.options}
            series={compGraphData}
            type="bar"
            height="100%"
            width="100%"
          />
        </div>
        <div className="h-[48%] w-full overflow-y-hidden px-4 pb-3 border-2 border-[#ffffff37] rounded-md">
          <ReactApexChart
            options={DailyReturnIndex.options}
            series={dailyGraphData}
            type="bar"
            height="100%"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};
