import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { reorderKeys } from "../../utils/ReorderKeys";

const InputCAPM = ({ companyData, comapnyName, newCompanyData }) => {
  const desiredOrder = [
    "date",
    "close_aapl",
    "close_spy",
    "pct_change_AAPL",
    "pct_change_spy",
  ];

  const tempCompanyData = reorderKeys(companyData, desiredOrder);

  const newDesiredOrder = [
    "returnsFromStock",
    "annualized_std_dev",
    "beta",
    "return_by_market",
    "required_rate_of_return",
  ];

  const newTempCompanyData = reorderKeys(newCompanyData, newDesiredOrder);

  return (
    <div className="flex gap-10">
      <div>
        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                {tempCompanyData?.map((row, i) => (
                  <TableCell key={i} className="!bg-[#2c3e50]">
                    {row.key1 === `close_${comapnyName.symbol}`
                      ? comapnyName.symbol
                      : row.key1 === "close_spy"
                      ? "SPY"
                      : row.key1 === `pct_change_${comapnyName.symbol}`
                      ? `Return ${comapnyName.symbol}`
                      : row.key1 === "pct_change_spy"
                      ? "Return SPY"
                      : row.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(tempCompanyData?.[0]?.values) &&
                tempCompanyData?.[0]?.values.map((_, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {tempCompanyData?.map((item, itemIndex) => (
                        <TableCell key={itemIndex}>
                          {item.key1 === "date"
                            ? moment(item.values[i]).format("DD/MM/YY")
                            : item.values[i]}
                          {item.key1 === "pct_change_spy" ||
                          item.key1 === `pct_change_${comapnyName.symbol}`
                            ? "%"
                            : ""}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <TableContainer>
          <Table className="border mt-5">
            <TableBody>
              {newTempCompanyData.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className={
                        row.title === "Ebit" ? "uppercase truncate" : "truncate"
                      }
                      component="th"
                    >
                      {row.key === "return_by_market"
                        ? "Rm"
                        : row.key === "annualized_std_dev"
                        ? "Standard Deviation"
                        : row.key === "required_rate_of_return"
                        ? `Required rate of return of ${comapnyName.symbol}`
                        : row.title}
                    </TableCell>
                    <TableCell
                      className={`truncate ${
                        row.key === "beta" ||
                        row.key === "required_rate_of_return"
                          ? "!bg-[#2c3e50]"
                          : ""
                      }`}
                    >
                      {row.values}
                      {row.key === "beta" ? "" : "%"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default InputCAPM;
