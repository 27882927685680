import React from 'react'
import Box from "@mui/material/Box";
import {FilterType, TimeFrame} from "./Types.js";
import {FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {endOfMonth, endOfYear, isWithinInterval, parseISO, startOfMonth, startOfYear} from "date-fns";


const Filters = ({dataList, setRows, rows}) => {
  const [filter, setFilter] = React.useState(false);
  const [filterType, setFilterType] = React.useState("all");
  const [childFilterType, setChildFilterType] = React.useState("",);
  const [selectedTimeFrame, setSelectedTimeFrame] = React.useState(TimeFrame.Week,);
  const handleFilterChange = (event) => {
    event.preventDefault();
    setFilterType(event.target.value);
  };
  const handleSortByLatest = (data) => {
    // Sort data by transaction date if sortByLatest is true
    if (data.length <= 0) {
      data = dataList;
    }
    let sortedData = [...data];

    sortedData = sortedData.sort((a, b) => new Date(b.file_date).getTime() - new Date(a.file_date).getTime(),);
    setRows(sortedData);
    setFilter(true);
    setChildFilterType(FilterType.LatestInsider);
  };


  React.useEffect(() => {
    // Filter data based on the selected filter type
    const filtered = filter ? rows.filter((transaction) => {
      if (filterType === "buy" || filterType === "sale") {
        return transaction.type.toLowerCase() === filterType;
      } else return true;
    }) : dataList.filter((transaction) => {
      if (filterType === "buy" || filterType === "sale") {
        return transaction.type.toLowerCase() === filterType;
      } else return true;
    });

    // Update the filteredData state with the filtered results
    setRows(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, filter]);


  function getTopThreeBuyTransactions(data, type,) {
    // Filter transactions of type "BUY"
    let resultData;
    if (data.length <= 0) {
      data = dataList;
    }
    const getDataOfFilter = (filterType) => {
      const buyTransactions = data.filter((transaction) => transaction.type.toLowerCase() === filterType,);

      // Sort the filtered transactions by value in descending order
      buyTransactions.sort((a, b) => b.value - a.value);
      // Take the top three transactions
      return buyTransactions.slice(0, 3);
    };
    if (type === "all") {
      resultData = getDataOfFilter("buy");
      resultData.push(...getDataOfFilter("sale"));
    } else {
      resultData = getDataOfFilter(type);
    }
    setFilter(true);
    setChildFilterType(FilterType.DailyFilter);
    setRows(resultData);
  }


  function filterTopInsiderTradingRecentWeekByValue(transactions, timeFrame,) {
    const currentDate = new Date();

    let startDate, endDate;

    switch (timeFrame) {
      case TimeFrame.Week:
        startDate = new Date();
        startDate.setDate(currentDate.getDate() - 7);
        endDate = currentDate;
        break;
      case TimeFrame.Month:
        startDate = startOfMonth(currentDate);
        endDate = endOfMonth(currentDate);
        break;
      case TimeFrame.Year:
        startDate = startOfYear(currentDate);
        endDate = endOfYear(currentDate);
        break;
      default:
        break;
    }

    // Filter transactions for the recent week
    if (transactions.length <= 0) {
      transactions = dataList;
    }
    const recentWeekTransactions = transactions.filter((transaction) => isWithinInterval(parseISO(new Date(transaction.transaction_date).toISOString()), {
      start: startDate, end: endDate,
    },));

    // Sort transactions by value in descending order
    recentWeekTransactions.sort((a, b) => b.value - a.value);

    setRows(recentWeekTransactions);
    setFilter(true);
    setChildFilterType(FilterType.InsiderByWeek);
  }

  function filterTopRelationRecentTimeFrame(transactions, timeFrame, relationship,) {
    const currentDate = new Date();
    let startDate, endDate;

    switch (timeFrame) {
      case TimeFrame.Week:
        startDate = new Date();
        startDate.setDate(currentDate.getDate() - 7);
        endDate = currentDate;
        break;
      case TimeFrame.Month:
        startDate = startOfMonth(currentDate);
        endDate = endOfMonth(currentDate);
        break;
      case TimeFrame.Year:
        startDate = startOfYear(currentDate);
        endDate = endOfYear(currentDate);
        break;
      default:
        break;
    }

    // Filter transactions for the specified time frame using date-fns
    const filteredTransactions = transactions.filter((transaction) => isWithinInterval(parseISO(new Date(transaction.transaction_date).toISOString()), {
      start: startDate, end: endDate,
    },));

    // Filter transactions with the specified relationship
    const topRelationTransactions = filteredTransactions.filter((transaction) => transaction.relationship.includes(relationship || "10"));

    // Sort transactions by any relevant criteria here
    // For example, you can sort them by transaction value in descending order
    topRelationTransactions.sort((a, b) => b.value - a.value);

    setRows(topRelationTransactions);
    setFilter(true);
    setChildFilterType(FilterType.Top10Percent);
  }

  const handleTimeFrameChange = (event) => {
    setSelectedTimeFrame(event.target.value);

    if (childFilterType === FilterType.InsiderByWeek) {
      filterTopInsiderTradingRecentWeekByValue(dataList, event.target.value,);
    }

    if (childFilterType === FilterType.Top10Percent) {
      filterTopRelationRecentTimeFrame(dataList, event.target.value, "10",);
    }
  };


  return (<>
    <Stack sx={{flexDirection: {xs: "column", md: "row"}}}>
      <Stack
        spacing={"2"}
        sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%",
          flexDirection: {xs: "column", md: "row"},
        }}
      >
        <Box sx={{paddingLeft: "20px"}}>
          {childFilterType.length > 0 && childFilterType !== FilterType.LatestInsider && (<FormControl>
            <InputLabel>Select Time Frame</InputLabel>
            <Select
              value={selectedTimeFrame}
              onChange={handleTimeFrameChange}
              label="Select Time Frame"
              size="small"
              defaultChecked={true}
            >
              <MenuItem value={TimeFrame.Week}>Week</MenuItem>
              <MenuItem value={TimeFrame.Month}>Month</MenuItem>
              {childFilterType !== "insider_by_week" && (
                <MenuItem value={TimeFrame.Year}>Year</MenuItem>)}
            </Select>
          </FormControl>)}
        </Box>
        <Stack spacing={2} direction={{xs: "column", md: "row"}}>

          <Button
            size="small"
            variant={childFilterType === FilterType.LatestInsider ? "outlined" : "text"}
            onClick={() => {
              handleSortByLatest(dataList);
            }}
          >
            Latest Insider Trading
          </Button>
          <Button
            size="small"
            variant={childFilterType === FilterType.InsiderByWeek ? "outlined" : "text"}
            onClick={() => {
              filterTopInsiderTradingRecentWeekByValue(dataList, TimeFrame.Week,);
              setSelectedTimeFrame(TimeFrame.Week);
            }}
          >
            Top Insider Trading Recent Week
          </Button>
          <Button
            size="small"
            variant={childFilterType === FilterType.Top10Percent ? "outlined" : "text"}
            onClick={() => {
              filterTopRelationRecentTimeFrame(dataList, TimeFrame.Week, "10",);
              setSelectedTimeFrame(TimeFrame.Week);
            }}
          >
            Top 10% Owner Trading Recent Week
          </Button>
          <Button
            size="small"
            variant={childFilterType === FilterType.DailyFilter ? "outlined" : "text"}
            onClick={() => {
              getTopThreeBuyTransactions(dataList);
            }}
          >
            Daily Filter
          </Button>
        </Stack>
        <Stack
          direction="row"
          sx={{
            display: "flex", alignItems: "center",
            flexDirection: {xs: "column", md: "row"},
          }}
        >
          {filter && (<Button
            onClick={() => {
              setFilter(false);
              setFilterType("all");
              setChildFilterType("");
            }}
          >
            Clear Filter
          </Button>)}
          <FormControl sx={{m: 1, minWidth: 120}}>
            <InputLabel
              id="demo-controlled-open-select-label"
              sx={{
                fontSize: "12px",
              }}
            >
              Select Filter
            </InputLabel>
            <Select
              label="Filter by Type"
              value={["all", "sale", "buy"].includes(filterType) ? filterType : ""}
              id="demo-controlled-open-select"
              onChange={handleFilterChange}
              size="small"
            >
              <MenuItem value="all">All Transactions</MenuItem>
              <MenuItem value="buy">Buy Transactions</MenuItem>
              <MenuItem value="sale">Sale Transactions</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>

  </>)
}

export default Filters