
export const TimeFrame = {
    Week : "week",
    Month : "month",
    Year : "year",
}

export const FilterType = {
    DailyFilter : "daily_filter",
    LatestInsider : "latest_insider",
    InsiderByWeek : "insider_by_week",
    Top10Percent : "top_10_percent",
}

export const Order = "asc" | "desc";
