import { Box, Grid, Paper, TableContainer } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Calender from "../../../BioTechComponents/Calender/Calender";
import { baseUrl } from "../../FMPUrlsAndEndpoint";
import ButtonComp from "../../ReUsableComps/BtnComp";
import TableComp from "../../ReUsableComps/TableComp";
import SearchComp from "../../ReUsableComps/SearchComp";
import { useNavigate } from "react-router-dom";

const CalendarElement = () => {
  const [calType, setCalType] = useState("earningCalendar"); //calendar type
  const [inputVal, setInputVal] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [backupData, setBackupData] = useState(null);
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState([]);

  //Api call
  useEffect(() => {
    callFMPCalApi();
  }, [calType]);
  const navigateTo = (calType) => {
    setCalType(calType);
  };
  const [fmpCal, setFmpCal] = useState([]);
  const [dates, setDates] = useState([]);
  let endpoint = baseUrl + "get_fmp_cal_data/";
  let data = {
    version: "v3",
    type:
      calType === "dividendCalendar"
        ? "stock_dividend"
        : calType === "split"
        ? "stock_split"
        : calType === "earningCalendar"
        ? "earning"
        : calType,
    view_type: "_calendar",
  };
  const callFMPCalApi = () => {
    setDataLoading(true);
    axios.post(endpoint, data).then((res) => {
      let responseData = res.data;
      responseData = JSON.parse(responseData.response);

      if (!responseData.success) {
        return;
      }
      let dateArr = [];

      responseData.fmp_cal_data?.map((item) => {
        if (calType === "dividendCalendar") {
          dateArr.push({ start: item.date, title: item.symbol, color: "blue" });
          dateArr.push({
            start: item.fiscalDateEnding,
            title: item.symbol,
            color: "red",
          });
          dateArr.push({
            start: item.updatedFromDate,
            title: item.symbol,
            color: "green",
          });
        } else if (calType === "ipo") {
          let acceptedDate = item.acceptedDate?.split(" ")[0];
          dateArr.push({
            start: acceptedDate,
            title: item.symbol,
            color: "blue",
          });
          dateArr.push({
            start: item.effectivenessDate,
            title: item.symbol,
            color: "red",
          });
          dateArr.push({
            start: item.filingDate,
            title: item.symbol,
            color: "green",
          });
        } else {
          dateArr.push({ start: item.date, title: item.symbol, color: "blue" });
        }
      });

      if (calType === "ipo") {
        let sorted_array = sortDatesMostRecentFirst(responseData.fmp_cal_data);
        setFmpCal(sorted_array);
        return;
      }
      if (calType === "earningCalendar") {
        
        fetchCompnayDetails(responseData.fmp_cal_data)
          .then((result) => {
            setFmpCal(result);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
      setDates(dateArr);
      setBackupData(responseData.fmp_cal_data);
      setFmpCal(responseData.fmp_cal_data);
      setDataLoading(false);
    });
  };

  //Pangination
  const fetchCompnayDetails = async (result) => {
    setDataLoading(true);
    const freshData = [];
    const promises = [];

    if (result.length) {
      result.slice(0, 50).forEach((item) => {
        const url = `https://financialmodelingprep.com/api/v3/profile/${item.symbol}?apikey=ff170cfbc214454f0a10844eb6e9606e`;
        const request = axios.get(url).then((response) => {
          if (response.data.length > 0) {
            const info = response.data[0]; 
            if (info.symbol === item.symbol) { 
              console.log(info,"info");
              console.log(info.image ,"image");
              freshData.push({ ...item, companyName: info.companyName ,companyLogo: info.image });
            }
          }
        });

        promises.push(request);
      });

      try {
        await Promise.all(promises);
        setDataLoading(false);
        console.log(freshData, "freshData");
        return freshData;
      } catch (error) {
        setDataLoading(false);
        console.error("Error:", error);
      }
    }
  };

  //helper
  function sortDatesMostRecentFirst(datesArray) {
    let updated_value = datesArray.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    // console.log(upda);
    return updated_value;
  }
  // Search functionality
  const setVal = (e) => {
    const val = e.target.value.toLowerCase();
    setInputVal(val);

    // Check if the input value is empty
    if (val === "") {
      // setFmpCal(backupData);
      callFMPCalApi();
      
    } else {
      const filteredData = fmpCal.filter((row) => {
        return (
          (row.symbol && row.symbol.toLowerCase().startsWith(val)) ||
          (row.name && row.name.toLowerCase().startsWith(val)) ||
          (row.sector && row.sector.toLowerCase().startsWith(val)) ||
          (row.subSector && row.subSector.toLowerCase().startsWith(val))
        );
      });
      setFmpCal(filteredData);
    }
  };

  //Handle table row clcik
  const handleClick = (data) => {
    navigate("/company-history", { state: data });
  };

const TableCellStyle = useMemo(() => {
    return {
      fontWeight: 400,
      color: "white",
    };
  }, []);

   const getStyle = useCallback((index) => {
    return { ...TableCellStyle, backgroundColor: "#inherit" }; //index % 2 === 0 ? '#002147' : '#12192c'
  }, []);

 const TableCellSX = useMemo(() => {
    return {
      // backgroundColor: "#12192c",
      fontWeight: 400,
      color: "white",
    };
  }, []);

  const tableCompMemo = useMemo(
    () => (
      <TableComp
        calType={calType}
        TableCellSX={TableCellSX}
        rows={fmpCal}
        getStyle={getStyle}
        parent={"calendar"}
        paperStyle={{
          height: "80vh",
          overflow: "auto",
          width: "50%",
          backgroundColor: "#12192c",
        }}
        onTouch={handleClick}
      />
    ),
    [calType, fmpCal]
  );

  const calMemo = useMemo(
    () =>
      calType !== "dividendCalendar" ? (
        <Calender propsEvents={dates} color={"null"} />
      ) : null,
    [dates]
  );
  return (
    <>
      {dataLoading ? (
        <div className="flex items-center justify-center h-[calc(100vh-158px)]">
          <div className="loader"></div>
        </div>
      ) : (
        <Box className='bg-primary no-dpace'>
          <div className={"flex justify-evenly items-center my-4"}>
            <Grid item md={8} xs={25}>
              <ButtonComp
                onClick={() => navigateTo("earningCalendar")}
                name={"Earning Calendar"}
                variant={
                  calType !== "earningCalendar" ? "outlined" : "contained"
                }
                className="searchBtn"
              />
            </Grid>
            <Grid item md={8} xs={25}>
              <ButtonComp
                onClick={() => navigateTo("dividendCalendar")}
                name={"DIVIDEND CALENDAR"}
                variant={
                  calType !== "dividendCalendar" ? "outlined" : "contained"
                }
                className="searchBtn"
              />
            </Grid>

            <Grid item md={8} xs={25}>
              <ButtonComp
                onClick={() => navigateTo("ipo")}
                name={"IPO"}
                variant={calType !== "ipo" ? "outlined" : "contained"}
                className="searchBtn"
              />
            </Grid>
            {/* <Grid item md={8} xs={25}>
          <ButtonComp
            onClick={() => navigateTo("split")}
            name={"SPLIT"}
            variant={calType !== "split" ? "outlined" : "contained"}
            className="searchBtn"
          />
        </Grid> */}
          </div>
          <SearchComp inputVal={inputVal} setInputVal={setVal} />

          <div
            className={`${
              calType !== "dividendCalendar" ? "flex" : "block"
            } justify-evenly items-center`}
          >
             <TableContainer component={Paper}> {tableCompMemo}</TableContainer>
           
            <div className={"w-[65%]"}>
              {calType !== "dividendCalendar" ? calMemo : null}
            </div>
          </div>
        </Box>
      )}
    </>
  );
};
export default CalendarElement;
