import React from 'react';
import './TextHightlighter.css';
const TextHighlighter = ({ text, positives, negatives }) => {
  const words = text.split(/\s+/);

  const getWordClass = (word) => {
    return positives.includes(word) ? 'positive-word' : negatives.includes(word) ? 'negative-word' : '';
  };

  return (
    words.map((word, index) => (
        <span key={index} className={getWordClass(word)}>
          {word}{' '}
        </span>
      ))
  );
};

export default TextHighlighter;
