import {HeadCell, Order} from "./Types.js";


// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort(
  array,
  comparator,
) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const getComparator = (
  order,
  orderBy,
)=> number => order === "desc"
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const headCells = (rows) => {
  if (rows.length === 0) {
    return [];
  }
  if (!rows[0]) return [];

  return Object.keys(rows[0])
    .map((property) => {
      if (property.toLowerCase() === "id") {
        return null;
      }

      return {
        id: property,
        numeric: typeof rows[0][property] === "number",
        disablePadding: false,
        label: (property=== "transaction"? property.concat("(%)"):property).replace(/_/g, " ").toUpperCase(),
      };
    })
    .filter(Boolean);
};