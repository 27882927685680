
export default function formatResponse(instance) {
	instance.interceptors.response.use(
		(resolvedResponse) => {
			return {
				data: resolvedResponse?.data,
				status: resolvedResponse.status,
				success: true,
			}
		},
		(error) => {
			const { response } = error
			return {
				status: response?.status || NaN,
				success: false,
				message: response?.data?.data?.message || 'Something went wrong',
			}
		},
	)
	return instance
}
