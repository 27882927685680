import React, {
  //   Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Grid, Input } from "@mui/material";
// import Apple from "../../../assets/images/appleLogo.png";
// import Google from "../../../assets/images/googleLogo.png";
// import Tesla from "../../../assets/images/teslaLogo.png";
// import Amazon from "../../../assets/images/amazonLogo.png";
import TableComp from "../../AiDocInsights/ReUsableComps/TableComp";
import ButtonComp from "../../AiDocInsights/ReUsableComps/BtnComp";
// import { TableRow, TableCell } from "@mui/material";
import axios from "axios";
// import { baseUrl } from "../../AiDocInsights/FMPUrlsAndEndpoint";
// import { styled, useTheme, alpha } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
import SearchComp from "../../AiDocInsights/ReUsableComps/SearchComp";

const MarketBroadView = () => {
  const [companyListingAccTo, setCompanyListingAccTo] = useState("sp500");
  const [sortAscending, setSortAscending] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);

  const [listingData, setListingData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [inputVal, setInputVal] = useState("");
  useEffect(() => {
    getCompanies();
  }, []);
 const getCompanies = () => {
  const endp = `https://dev-websocket.stockiworld.com/api/get_fmp_stock_data/`;

  // Define the data you want to send in the POST request (if any).
  const postData = {
  "type": companyListingAccTo,
  };

  axios.post(endp, postData)
    .then((res) => {
      let responseData = res.data;
      setListingData(responseData?.fmp_stock_data);
      setAllData(responseData);
    })
    .catch((error) => {
      // Handle any errors that occur during the POST request.
      console.error('Error:', error);
    });
};
  // const getCompanies = () => 
  //   const page = 1;
  //   const pageSize 
  //   const endp = `https://company.stockiworld.com/api/company-details/?page=${page}&page_size=${pageSize}`;

  //   axios.get(endp).then((res) => {
  //     let responseData = res.data;
  //     setListingData(responseData);
  //     setAllData(responseData);
  //   });
  // };
  

  const navigateTo = (cLAT) => {
    setCompanyListingAccTo(cLAT); //companyListingAccTo
  };

  const TableCellStyle = useMemo(() => {
    return {
      fontWeight: 800,
      color: "white",
    };
  }, []);
  const getStyle = useCallback((index) => {
    return { ...TableCellStyle, backgroundColor: "#002147" }; //index % 2 === 0 ? '#12192c' : '#002147'
  }, []);

  const TableCellSX = useMemo(() => {
    return {
      backgroundColor: "#12192c",
      fontWeight: 800,
      color: "white",
    };
  }, []);
  function compareByNumericValue(a, b, ascending) {
    const numA = parseFloat(a.marketCap);
    const numB = parseFloat(b.marketCap);

    return ascending ? numA - numB : numB - numA;
  }

  const marketCapFunc = () => {
    let sortedData = sortAscending
      ? listingData.sort((a, b) => compareByNumericValue(a, b, true))
      : listingData.sort((a, b) => compareByNumericValue(a, b, false));
    setSortAscending(!sortAscending);
    console.log("sortedData", sortedData);
    setListingData(sortedData);
  };
  useEffect(() => {
    getCompanies();
  }, [companyListingAccTo]);
  const setVal = (e) => {
    let val = e.target.value;
    setInputVal(val);
    let filteredData = val
      ? allData.filter(
          (row) =>
            row.symbol?.toLowerCase().startsWith(val?.toLowerCase()) ||
            row.name?.toLowerCase().startsWith(val?.toLowerCase()) ||
            row.sector?.toLowerCase().startsWith(val?.toLowerCase()) ||
            row.subSector?.toLowerCase().startsWith(val?.toLowerCase())
        )
      : allData;
    setListingData(filteredData);
  };
  return (
    <Box className="bg-[#001219]">
      <div className="ml-5">
        <SearchComp inputVal={inputVal} setInputVal={setVal} />
      </div>
      {/* <div className="flex items-center">
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <InputBase
          sx={StyledInputBase}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => setInputVal(e.target.value)}
          value={inputVal}
        />
      </div> */}
      <div className={"flex justify-evenly items-center py-4"}>
        <Grid item md={2} xs={25}>
          <ButtonComp
            onClick={() => navigateTo("sp500")}
            name={"SP 500"}
            variant={companyListingAccTo !== "sp500" ? "outlined" : "contained"}
            className="table-sentiment-button"
          />
        </Grid>
        <Grid item md={2} xs={25}>
          <ButtonComp
            onClick={() => navigateTo("dowjones")}
            name={"Dow 30"}
            variant={
              companyListingAccTo !== "dowjones" ? "outlined" : "contained"
            }
            className="searchBtn"
          />
        </Grid>
        <Grid item md={8} xs={25}>
          <ButtonComp
            onClick={() => navigateTo("nasdaq")}
            name={"Nasdaq 100"}
            variant={
              companyListingAccTo !== "nasdaq" ? "outlined" : "contained"
            }
            className="searchBtn"
          />
        </Grid>
      </div>
      <TableComp
        calType={companyListingAccTo}
        TableCellSX={TableCellSX}
        rows={listingData}
        getStyle={getStyle}
        parent={"stock"}
        filterVal={inputVal}
        marketCapFunc={marketCapFunc}
      />
    </Box>
  );
};
export default MarketBroadView;
