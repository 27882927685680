import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { endpoint, adiEndpoint } from "../../FMPUrlsAndEndpoint";
import LeftPanel from "../../Panels/LeftPanel";
import RightPanel from "../../Panels/RightPanel";
import Tesla from "../../../../assets/images/teslaLogo.png";

const Home = () => {
  useEffect(() => {
    check();
  }, []);
  const check = () => {
    const endp =
      "https://adi.stockiworld.com/api/v1/ask_question/?vectors_list=['AAPL_3_2020_Earning_Call_vectors', 'AAPL_4_2020_Earning_Call_vectors']&question=All negative facts about company growth";
    // /api/v1/get_company_data_details?company_id=TSLA&doc_type=Earning_Call";
    // /api/v1/get_similar_docs/?query=Any Negative trend in data&company_ids=["TSLA"]&max_num_of_docs=3
    console.log("endp", endp);
    axios.get(endp).then((res) => {
      let responseData = res.data;
      console.log("resdatatatatat", responseData);
    });
  };
  const [activatedButton, setActivatedButton] = useState(null);
  const [label, setLabel] = useState("Earnings Calls");
  const [content, setContent] = useState(
    "Dummy Text. Content will be displayed here"
  );
  const [positives, setPositives] = useState(["Dummy", "will", "here"]);
  const [negatives, setNegatives] = useState(["Text", "Content", "displayed"]);
  const [qyState, setQyState] = useState([]);
  const handleButton = (btnName, label) => {
    setLabel(label);
    setActivatedButton(btnName);
  };
  const initialState = useMemo(() => {
    return {
      selectedCompany: {
        name: "Tesla",
        logo: Tesla,
        companyId: "TSLA",
      },
      year: null,
      quarter: null,
      quarters: [],
    };
  }, []);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    callTranscript();
  }, [
    state.selectedCompany?.companyId,
    state.year,
    state.quarter,
    state.quarters,
  ]);

  useEffect(() => {
    // getDocsWithSentiments();
    // getCompanyDetails();
  }, []);
  const setStates = (company, year, quarter, qyToBeSelectedOrRemoved) => {
    let filteredQuarters = state.quarters.includes(quarter)
      ? state.quarters?.filter((q) => q !== quarter)
      : [...state.quarters, quarter];
    setState(
      company && Object.keys(company)
        ? { ...state, selectedCompany: company }
        : {
            ...state,
            year: year,
            quarter: quarter,
            quarters: filteredQuarters,
          }
    );
    if (year || quarter) {
      let filteredQY = qyState.includes(qyToBeSelectedOrRemoved)
        ? qyState.filter((qy) => qy !== qyToBeSelectedOrRemoved)
        : [...qyState, qyToBeSelectedOrRemoved];
      setQyState(filteredQY);
    }
  };
  const callTranscript = () => {
    if (state.companyId || state.year || state.quarter) {
      let ep = endpoint(
        `v3/earning_call_transcript/${state.selectedCompany?.companyId}${
          state.quarter ? `?quarter=${state.quarter}` : ""
        }${state.year ? `&year=${state.year}` : ""}`
      );
      try {
        axios.post(ep).then((res) => {
          let responseData = res.data;
          setContent(responseData ? responseData[0]?.content : "Dummy Text");
        });
      } catch (e) {
        console.log("eeerrr", e);
      }
    }
  };

  const getDocsWithSentiments = () => {
    let ep = adiEndpoint + "v1/get_docs_with_sentiments/";
    let data = [
      {
        company_id: "TSLA",
        doc_type: "Earning_Call",
        quarter: 2,
        year: 2021,
      },
      {
        company_id: "AAPL",
        doc_type: "Earning_Call",
        quarter: 3,
        year: 2020,
      },
    ];
    axios.get(ep, data).then((res) => {
      // console.log("getDocsWithSentiments", res)
    });
  };
  const getCompanyDetails = () => {
    let ep =
      adiEndpoint +
      "v1/get_company_data_details?company_id=TSLA&doc_type=Earning_Call";
    axios.get(ep).then((res) => {
      // console.log("getCompanyDetails", res)
    });
  };
  const getAnswer = () => {
    try {
      let ep = endpoint(
        "v1/get_company_data_details?company_id=TSLA&doc_type=Earning_Call"
      );
      axios
        .get(ep)
        .then((res) => {
          let responseData = res.data;
          // console.log('getAnswer', responseData)
        })
        .catch((e) => console.log("err", e));
    } catch (e) {
      console.log("err", e);
    }
  };
  return (
    <div className={"flex flex-col sm:flex-row h-[50rem]"}>
      <LeftPanel
        selectedCompany={state.selectedCompany}
        setStates={setStates}
        callTranscript={callTranscript}
        handleButton={handleButton}
        activatedButton={activatedButton}
        label={label}
        qyState={state}
      />
      <RightPanel
        getAnswer={getAnswer}
        label={label}
        state={state}
        content={content}
        negatives={negatives}
        positives={positives}
      />
    </div>
  );
};
export default Home;
