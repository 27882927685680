
import { configureStore } from "@reduxjs/toolkit";

import FinanceReducer from "./finance/finance-slice";

export const store = configureStore({
  reducer: {
    finance: FinanceReducer,
  },
});
