/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { Button, Tab } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../Home/sidebar/sidebar";
import { formatValue } from "../../utils/FormatValue";
import { camelCaseToWords } from "../../utils/CamelCaseToWords";
import SectorSummery from "./sectorSummery";



const FundHoldings = () => {
    const [comapnyName, setComapnyName] = useState({
        symbol: "AAPL",
        name: "Apple Inc.",
    });

    // const companyList = useRef([])
    const [companyData, setCompanyData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [defaultPeriod, setDefaultPeriod] = useState("annual");
    const [companyList, setComapnyList] = useState([]);
    const [companySearch, setComapnySearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [type, setType] = useState("sector-statements");


    // const fetchData = async () => {
    //     await axios.get(
    //         `https://company.stockiworld.com/api/company-details/?page=1&page_size=50`
    //     ).then((res) => {
    //         // companyList.current = [...res.data]
    //         setComapnyList([...res.data])
    //     })
    // }

    // useEffect(() => {
    //     fetchData()
    // }, [])

    // useEffect(() => {
    //     setDataLoading(true);
    //     axios
    //         .get(
    //             `https://company.stockiworld.com/api/${type}/${defaultPeriod}/${comapnyName.symbol}/`
    //         )
    //         .then((res) => {
    //             const formattedData = [];

    //             let obj = Object.keys(res.data)[0] !== "period" ? Object.values(res.data)[0] : Object.values(res.data)[1];

    //             Object.keys(obj).map((key, index) => {
    //                 let nextKey = Object.keys(obj)[index + 1];

    //                 if (key === "period" && nextKey === "calendarYear") {
    //                     let newObj = {
    //                         key,
    //                         title: "",
    //                         values: formatValue(obj[key]),
    //                         year: obj[nextKey]
    //                     }
    //                     formattedData.push(newObj)
    //                 }

    //                 if (!key.includes('_ratio')) {
    //                     let newObj = {
    //                         key,
    //                         title: camelCaseToWords(key),
    //                         // values: obj[key]
    //                         values: formatValue(obj[key])
    //                     }

    //                     if (nextKey?.includes('_ratio')) {
    //                         newObj.ratio = obj[nextKey];
    //                     }


    //                     if ((key !== "period" && nextKey !== "calendarYear")) {
    //                         if (key !== "calendarYear") {
    //                             console.log(newObj.key)

    //                             formattedData.push(newObj)
    //                         }
    //                     }
    //                 }
    //             })

    //             setCompanyData(formattedData);
    //             setDataLoading(false);
    //         });
    // }, [comapnyName, defaultPeriod, type]);

    // useEffect(() => {
    //     setSearchLoader(true)
    //     const getData = setTimeout(() => {
    //         axios
    //             .get(`https://company.stockiworld.com/api/company-details/?page=1&symbol=${companySearch}`)
    //             .then((response) => {
    //                 // companyList.current = response.data;
    //                 setComapnyList([...response.data])
    //                 setSearchLoader(false)
    //             });
    //     }, 2000)

    //     return () => clearTimeout(getData)
    // }, [companySearch])

    return (
        <div className="bg-primary">
            <div className="main_wrapper">
                <div>
                    <Navbar />
                </div>
                {/* <div>
                    <Sidebar />
                </div> */}

                <div className="overflow-auto h-[calc(100vh-64px)] w-[calc(100vw-125px)]">
                    <div className="flex w-[calc(100vw-125px)] justify-between pt-5 px-5">
                        <div className="flex gap-3">
                            <Button
                                variant="outlined"
                                className={`custom-button ${type === "sector-statements" && "active"
                                    }`}
                                onClick={() => {
                                    setType("sector-statements");
                                }}
                            >
                                SECTOR SUMMARY
                            </Button>
                            <Button
                                variant="outlined"
                                className={`custom-button ${type === "balance-sheet" && "active"
                                    }`}
                                onClick={() => {
                                    setType("balance-sheet");
                                }}
                            >
                               ETF HOLDINGS 
                            </Button>
                            <Button
                                variant="outlined"
                                className={`custom-button ${type === "cash-flow" && "active"
                                    }`}
                                onClick={() => {
                                    setType("cash-flow");
                                }}
                            >
                               MUTUAL FUND HOLDINGS
                            </Button>
                            {/* <Button
                                variant="outlined"
                                className={`custom-button ${type === "ratio" && "active"
                                    }`}
                                onClick={() => {
                                    setType("ratio");
                                }}
                            >
                                Ratio
                            </Button> */}
                        </div>

                        {/* <div className="flex gap-3">
                            <Button
                                variant="outlined"
                                className={`custom-button ${defaultPeriod === "annual" && "active"
                                    }`}
                                onClick={() => {
                                    setDefaultPeriod("annual");
                                }}
                            >
                                Annual
                            </Button>
                            <Button
                                variant="outlined"
                                className={`custom-button ${defaultPeriod === "quarterly" && "active"
                                    }`}
                                onClick={() => {
                                    setDefaultPeriod("quarterly");
                                }}
                            >
                                Quaterly
                            </Button>
                        </div> */}
                    </div>

                    <div className="my-5 mx-5">
                    

                        {dataLoading ? (
                            <div className="flex items-center justify-center h-[calc(100vh-158px)]">
                                <div className="loader"></div>
                            </div>
                        ) : (
                         <SectorSummery/>
                            // <TableContainer>
                            //     <Table aria-label="vertical header table" className="border mt-5">
                            //         <TableBody>
                            //             {companyData.map((row, i) => (
                            //                 <TableRow >
                            //                     <TableCell component="th">
                            //                         {row.title}
                            //                         {/* {row.ratio && <div>%</div>} */}
                            //                     </TableCell>

                            //                     {row.values?.map(
                            //                         (rowItem, rowInd) => {
                            //                             return (
                            //                                 <TableCell key={rowInd} className="!text-center ">
                            //                                     {rowItem ? rowItem : "-"}
                            //                                     <div>
                            //                                         {row.year && row.year[rowInd]}
                            //                                     </div>
                            //                                     {row.ratio &&
                            //                                         <div className={`${row.ratio[rowInd] > 0 ? 'text-green-500' : 'text-red-500'} `}>
                            //                                             {row.ratio[rowInd] ? row.ratio[rowInd] > 0 ? `+${row.ratio[rowInd]}%` : `${row.ratio[rowInd]}%` : "-"}
                            //                                         </div>
                            //                                     }
                            //                                 </TableCell>
                            //                             );
                            //                         }
                            //                     )}
                            //                 </TableRow>
                            //             ))}
                            //         </TableBody>
                            //     </Table>
                            // </TableContainer>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default FundHoldings;
