import axios from 'axios'
import { API_SERVER_URL } from '../Url'
import formatResponse from './format-response'

const generalMiddleware = axios.create({
	baseURL: API_SERVER_URL,
})

generalMiddleware.interceptors.request.use((requestConfig) => {
	requestConfig.headers = {}
	return requestConfig
})

export default formatResponse(generalMiddleware)
