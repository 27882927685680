import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { format, parseISO, } from "date-fns";
import { Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppSelector } from "../../../redux/hooks";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { getComparator, headCells, stableSort } from "./Helper";
import Filters from "./Filters";
import List from "@mui/material/List";
import Dialog from "@mui/material/Dialog";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    tableLayout: "auto", // Set the table layout to "auto"
  },
});


const Transition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function EnhancedTable({ dataList }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("file_date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [headCs, setHeadCs] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const classes = useStyles();
  const isLoading = useAppSelector((state) => state.finance.isLoading);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (dataList && dataList.length > 0) {
      setRows(dataList);
      setHeadCs(headCells(dataList));
    }
  }, [dataList]);

  const handleRequestSort = (_event, property,) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = dataList.map((n) => n.owner_name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    alert(name)
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1),);
    }

    // setSelected(newSelected);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event,) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


  const handleClickOpen = () => {
    setOpen(true);
  };

  //dialog
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };


  return (<Box sx={{ width: "100%" }}>
    <Paper sx={{ width: "100%", mb: 2 }}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleClickOpen={handleClickOpen}
        setSelected={setSelected}
      />
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Filters
          dataList={dataList}
          setRows={setRows}
          rows={rows} />
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose(selectedValue)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Filters
            </Typography>
            <Button autoFocus color="inherit" onClick={() => handleClose(selectedValue)}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Box
            sx={{ width: "100%", height: "100vw", display: "flex", "alignItems": "center", "justifyContent": "center" }}>
            <Filters
              dataList={dataList}
              setRows={setRows}
              rows={rows} />
          </Box>
        </List>
      </Dialog>


      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          className={classes.table}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCs={headCs}
          />

          <TableBody>
            {isLoading && (<TableRow>
              <TableCell colSpan={10} align="center">
                Loading Data...
                <Skeleton animation="wave" height={48} />
              </TableCell>
            </TableRow>)}

            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, _index) => {
                const isItemSelected = isSelected(String(row.id));
                return (<TableRow
                  hover
                  onClick={(event) => handleClick(event, String(row.id))}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={String(row.id)}
                  selected={isItemSelected}
                  sx={{
                    color: "white !important",
                    cursor: "pointer",
                    background: String(row.type).toLowerCase() == "buy" ? "rgb(20, 89, 57)" : "rgb(120, 55, 63)",
                  }}
                >
                  {headCs.map((headCell) => (<TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {(() => {
                      switch (headCell.label.toLowerCase()) {
                        case "link":
                          return (<a href={`${row[headCell.id]}`}>
                            {
                              "www.sec.gov/"}
                          </a>);
                        case "transaction date":
                          return format(parseISO(String(row[headCell.id])), "MMM dd",);
                        case "file date":
                          return format(parseISO(String(row[headCell.id])), "MMM dd hh:mm a",);
                        case "symbol":
                          return <Typography sx={{
                            color: "rgb(46, 227, 228)",
                            "&:hover": {
                              color: "skyblue",
                              textDecoration: "underline",
                            }
                          }}
                            onClick={() => {
                                window.location.href = `/insideTrading?symbol=${row[headCell.id]}`
                              }}
                          >
                            {row[headCell.id]}
                          </Typography>
                        default:
                          return row[headCell.id];
                      }
                    })()}
                  </TableCell>))}
                </TableRow>);
              })}
            {emptyRows > 0 && (<TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={headCells.length + 1} />
            </TableRow>)}
            {rows && rows.length <= 0 && !isLoading && (<TableRow>
              <TableCell colSpan={10} align="center">
                No Data Available
              </TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 10, 5]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  </Box>);
}

export default EnhancedTable;
