import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const Graham = ({ companyData }) => {
  console.log("Graham", companyData);
  const ttmKey = [
    "roeTTMValues",
    "retentionRatioTTMValues",
    "epsTTMValues",
    "growthRateByFormulaTTMValues",
  ];

  const resultKey = [
    "intrinsicValueResults",
    "buypriceResults",
    "currentMarketPriceResults",
  ];

  const assumptionKey = [
    "terminalGrowthRateAssumptions",
    "growthRateAverageFor5TO10YearsAssumptions",
    "currentYieldOfAAARatedBondYearlyAssumptions",
    "marginOfSafetyRequiredAssumptions",
  ];

  return (
    <div className="flex gap-10">
      <div>
        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={2}>
                  TTM Values
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map((row, i) => {
                return (
                  ttmKey.includes(row.key) && (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={row.title === "Ebit" ? "uppercase" : ""}
                        component="th"
                      >
                        {row.key === "roeTTMValues"
                          ? "ROE"
                          : row.key === "retentionRatioTTMValues"
                          ? "Retention ratio"
                          : row.key === "growthRateByFormulaTTMValues"
                          ? "Growth rate by formula"
                          : row.key === "epsTTMValues"
                          ? "EPS"
                          : row.title}
                      </TableCell>
                      <TableCell>
                        {row.values}
                        {(row.key === "roeTTMValues" ||
                          row.key === "retentionRatioTTMValues" ||
                          row.key === "epsTTMValues") &&
                          "%"}
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={2}>
                  Results
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map((row, i) => {
                return (
                  resultKey.includes(row.key) && (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th">{row.title}</TableCell>
                      <TableCell>{row.values}</TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <TableContainer>
          <Table className="border mt-5">
            <TableHead>
              <TableRow className="!bg-[#161a20]">
                <TableCell className="!text-center !bg-[#2c3e50]" colSpan={2}>
                  Assumptions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData.map((row, i) => {
                return (
                  assumptionKey.includes(row.key) && (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={row.title === "Ebit" ? "uppercase" : ""}
                        component="th"
                      >
                        {row.key === "terminalGrowthRateAssumptions"
                          ? "Terminal growth rate"
                          : row.key ===
                            "growthRateAverageFor5TO10YearsAssumptions"
                          ? "Growth rate average for 5-10 years"
                          : row.key ===
                            "currentYieldOfAAARatedBondYearlyAssumptions"
                          ? "Current yield of AAA rated bond (Y)"
                          : row.key === "marginOfSafetyRequiredAssumptions"
                          ? "Margin of safety required"
                          : row.title}
                      </TableCell>
                      <TableCell>{row.values}%</TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Graham;
