import Toolbar from "@mui/material/Toolbar";
import {alpha} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeselectIcon from "@mui/icons-material/Deselect";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";

function EnhancedTableToolbar(props) {
    const { numSelected, setSelected, handleClickOpen } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity,
                        ),
                }),
            }}
        >
            {numSelected > 0
                ? (
                    <Typography
                        sx={{ flex: "1 1 100%" }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                )
                : (
                    <Typography
                        sx={{ flex: "1 1 100%" }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Insider Data
                    </Typography>
                )}
            {numSelected > 0
                ? (
                    <Tooltip title="Deselect all" onClick={() => setSelected([])}>
                        <IconButton>
                            <DeselectIcon />
                        </IconButton>
                    </Tooltip>
                )
                : (
                  <Box sx={{display:{xs:"block", md:"none"}}}>
                    <Tooltip title="Filter list">
                        <IconButton onClick={handleClickOpen}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                  </Box>
                )}
        </Toolbar>
    );
}

export default EnhancedTableToolbar